import assignIn from 'lodash/assignIn'
import assignInWith from 'lodash/assignInWith'
import assignWith from 'lodash/assignWith'
import camelCase from 'lodash/camelCase'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import escape from 'lodash/escape'
import isArray from 'lodash/isArray'
import isBoolean from 'lodash/isBoolean'
import isDate from 'lodash/isDate'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isError from 'lodash/isError'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import isNull from 'lodash/isNull'
import isNumber from 'lodash/isNumber'
import isFinite from 'lodash/isFinite'
import isObject from 'lodash/isObject'
import isObjectLike from 'lodash/isObjectLike'
import isString from 'lodash/isString'
import padEnd from 'lodash/padEnd'
import padStart from 'lodash/padStart'
import replace from 'lodash/replace'
import sortBy from 'lodash/sortBy'
import trim from 'lodash/trim'
import chunk from 'lodash/chunk'
import pick from 'lodash/pick'
import capitalize from 'lodash/capitalize'

export type { DebouncedFunc } from 'lodash'

export const StringCompare = (a: string, b: string) => a.localeCompare(b)

export const StringCICompare = (() => {
    const options: Intl.CollatorOptions = {
        sensitivity: 'base'
    }
    return (a: string, b: string) => a.localeCompare(b, undefined, options)
})()

export function ArrayStringCompare(a: string[] | null | undefined, b: string[] | null | undefined) {
    if (a === b) {
        return 0
    }

    if(!a) {
        return 1
    }

    if(!b) {
        return -1
    }

    if (a.length !== b.length) {
        return a.length - b.length
    }

    for (let i = 0; i < a.length; i++) {
        const ai = a[i]
        const bi = b[i]

        if (ai === bi) {
            continue
        } else if (ai > bi) {
            return 1
        } else {
            return -1
        }
    }

    return 0
}

function isNotNil(value: unknown) {
    return !isNil(value)
}

function isNotNull(value: unknown) {
    return !isNull(value)
}

export const lodash = {
    assignIn,
    assignInWith,
    assignWith,
    camelCase,
    capitalize,
    cloneDeep,
    chunk,
    pick,
    escape,
    debounce,
    isArray,
    isBoolean,
    isDate,
    isEmpty,
    isEqual,
    isError,
    isFunction,
    isNil,
    isNotNil,
    isNull,
    isNotNull,
    isNumber,
    isFinite,
    isObject,
    isObjectLike,
    isString,
    padStart,
    padEnd,
    replace,
    sortBy,
    trim,

    // custom
    StringCompare,
    StringCICompare,
    ArrayStringCompare
}

export default lodash