import React from 'react'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { FilterExtraAeScope } from '../../tcm_scopes'
import { Checkbox, FormControlLabel } from '@mui/material'

type FilterExtraAeViewProps = {
    className?: string
    scope: FilterExtraAeScope
}

class FilterExtraAeViewClass implements FCClassContext<FilterExtraAeViewProps> {
    scope!: FilterExtraAeScope

    onSyncState({ scope }: FilterExtraAeViewProps) {
        this.scope = scope
    }

    render({ className, scope }: FilterExtraAeViewProps): JSX.Element {
        return (
            <FormControlLabel
                className={className}
                label={scope.domain === 0 ? 'Todos' : scope.domain === 1 ? 'CNAE principal' : ' CNAE secundário'}
                control={
                    <Checkbox
                        checked={true}
                        onChange={this.emitOnDomainChanged}
                    />
                }
            />
        )
    }

    emitOnDomainChanged = () => {
        this.scope.onChange((this.scope.domain + 1) % 3)
    }
}

export const FilterExtraAeView = classToFComponent(FilterExtraAeViewClass, React)
export default FilterExtraAeView
