import React from 'react'
import clsx from 'clsx'
import { Lodash } from 'lang-utils'
import { Logger } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { keyframes } from 'tss-react'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'

import {
    OverlayScope,
    FilterEntryScope,
    OverlayLivreScope,
    OverlayEmpresaScope,
    OverlayPessoaScope,
    OverlaySocioScope,
    OverlayCnesScope,
    OverlayPessoaAndSocioScope,
    OverlayOperadoraScope
} from '../ta_scopes'

import DownloadIcon from '@mui/icons-material/Download'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

const LOG = Logger.get('TA-OVERLAY-VIEW')

const lodash = Lodash.singleton

const useStyles = makeStyles()({
    view: {
        pointerEvents: 'none',
        display: 'flex',
        flexDirection: 'row'
    },
    buttonBar: {
        pointerEvents: 'auto',
        marginTop: 30,
        alignSelf: 'start'
    },
    controlButton: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 40,
        width: 40,
        cursor: 'pointer',
        backgroundColor: 'rgba(0,0,0,0.6)',
        outline: 'none',
        ':focus, :hover': {
            backgroundColor: 'black'
        },
        '.MuiSvgIcon-root': {
            color: 'white'
        }
    },
    topRound: {
        borderStartStartRadius: 4
    },
    bottomRound: {
        borderEndStartRadius: 4
    },
    body: {
        pointerEvents: 'auto',
        backgroundColor: 'white',
        //width: 250,
        borderLeft: 'rgba(0,0,0,0.6) solid 1px',
        borderTop: 'rgba(0,0,0,0.6) solid 1px',
        borderBottom: 'rgba(0,0,0,0.6) solid 1px',
        borderStartStartRadius: 4,
        borderEndStartRadius: 4,
        overflowY: 'auto',
        padding: '5px 5px',

        display: 'flex',
        flexDirection: 'column',
        rowGap: 5
    },
    bodyOpen: {
        width: 250
    },
    bodyClose: {
        width: 0,
        display: 'none'
    },
    bodyOpenAnimation: {
        animation: `${keyframes`
        from  {
            width: 0;
        }
        to {
            width: 250px;
        }
        `} 0.1s forwards ease-in`
    },
    bodyCloseAnimation: {
        animation: `${keyframes`
        from  {
            width: 250px;
        }
        to {
            width: 0;
            display: none;
        }
        `} 0.1s forwards ease-in`
    },
    filterEntry: {
        backgroundColor: 'white',
        padding: '2px',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        '& .labels': {
            flexGrow: 1
        },
        '& .buttons': {}
    },
    filterGroup: {
        borderRadius: 6,
        backgroundColor: '#d2d2d2',
        padding: '0 5px 5px 5px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: 3,

        '& h6': {
            borderBottom: 'black solid 2px'
        }
    },
    filterForLivre: {},
    filterForEmpresa: {},
    filterForCnes: {},
    filterForSocio: {},
    filterForPessoa: {},
    filterForPessoaAndSocio: {},
    filterForOperadora: {},
    vGap: {
        height: 10
    },
    downloadProgress: {
        zoom: 0.5
    }
})

type OverlayViewProps = {
    className?: string
    scope: OverlayScope
}

class OverlayViewClass implements FCClassContext<OverlayViewProps> {
    // :: Fields
    scope: OverlayScope
    prevExpanded = false

    constructor({ scope }: OverlayViewProps) {
        this.scope = scope
        this.prevExpanded = scope.expanded
    }

    // :: Emissors

    readonly emitFilterToggleClick = () => this.scope.onFilterToggleClick().catch(LOG.caught)
    readonly emitDownloadClick = () => this.scope.onDownloadClick().catch(LOG.caught)

    // :: Methods

    render({ className, scope }: OverlayViewProps) {
        this.scope = scope

        const classes = useStyles().classes
        let bodyExpanded = scope.expanded ? classes.bodyOpen : classes.bodyClose

        if (this.prevExpanded !== scope.expanded) {
            bodyExpanded = this.prevExpanded ? classes.bodyCloseAnimation : classes.bodyOpenAnimation
            this.prevExpanded = scope.expanded
        }

        // prettier-ignore
        return (
            <div className={clsx(classes.view, className)}>
                <div className={classes.buttonBar}>
                    <ControlButton 
                        className={clsx(classes.topRound, classes.bottomRound)}
                        onClick={this.emitFilterToggleClick}>
                        <FilterAltIcon />
                    </ControlButton>
                    <div className={classes.vGap}/>
                    {scope.downloadEnabled &&
                        <ControlButton
                            className={clsx(classes.topRound, classes.bottomRound)}
                            onClick={this.emitDownloadClick}>
                            {!scope.downloading && <DownloadIcon />}
                            {scope.downloading && <CircularProgress className={classes.downloadProgress} />}
                        </ControlButton>
                    }
                </div>
                <div className={clsx(classes.body, bodyExpanded)}>
                    {scope.livre.hasContent()          && <FilterForLivreView          className={classes.filterForLivre}          scope={scope.livre} />}
                    {scope.empresa.hasContent()        && <FilterForEmpresaView        className={classes.filterForEmpresa}        scope={scope.empresa} />}
                    {scope.cnes.hasContent()           && <FilterForCnesView           className={classes.filterForCnes}           scope={scope.cnes} />}
                    {scope.socios.hasContent()         && <FilterForSocioView          className={classes.filterForSocio}          scope={scope.socios} />}
                    {scope.pessoa.hasContent()         && <FilterForPessoaView         className={classes.filterForPessoa}         scope={scope.pessoa} />}
                    {scope.pessoaAndSocio.hasContent() && <FilterForPessoaAndSocioView className={classes.filterForPessoaAndSocio} scope={scope.pessoaAndSocio} />}
                    {scope.operadoras.hasContent() && <FilterForOperadoraView className={classes.filterForOperadora} scope={scope.operadoras} />}
                </div>
            </div>
        )
    }
}
export const OverlayView = classToFComponent(OverlayViewClass)
export default OverlayView

//////////////

type ControlButtonProps = {
    className?: string
    children?: React.ReactNode
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

function ControlButton({ className, children, onClick }: ControlButtonProps) {
    const { classes } = useStyles()
    return (
        <a className={clsx(classes.controlButton, className)} onClick={onClick}>
            {children}
        </a>
    )
}

//////////////

type FilterEntryProps = {
    className?: string
    tag?: string
    scope: FilterEntryScope
}

class FilterEntryViewClass implements FCClassContext<FilterEntryProps> {
    scope!: FilterEntryScope

    // :: Methods

    emitRemoveClick = () => {
        this.scope.onRemoveClick().catch(LOG.caught)
    }

    render({ className, tag, scope }: FilterEntryProps) {
        this.scope = scope

        const classes = useStyles().classes

        return (
            <div className={clsx(classes.filterEntry, className)}>
                <div className="labels">
                    {lodash().isString(tag) && <span>{tag}:&nbsp;</span>}
                    <span>{scope.label}</span>
                </div>
                <div className="buttons">
                    <IconButton onClick={this.emitRemoveClick}>
                        <ClearIcon />
                    </IconButton>
                </div>
            </div>
        )
    }
}
const FilterEntryView = classToFComponent(FilterEntryViewClass)

//////////////

type LivreViewProps = {
    className?: string
    scope: OverlayLivreScope
}

class FilterForLivreViewClass implements FCClassContext<LivreViewProps> {
    scope!: OverlayLivreScope

    // :: Methods

    render({ className, scope }: LivreViewProps) {
        this.scope = scope

        const classes = useStyles().classes

        return (
            <div className={clsx(classes.filterGroup, className)}>
                <Typography variant="h6" noWrap gutterBottom>
                    Global
                </Typography>
                {scope.texts && scope.texts.map((s) => <FilterEntryView key={s.id} scope={s} />)}
            </div>
        )
    }
}
const FilterForLivreView = classToFComponent(FilterForLivreViewClass)

//////////////

type FilterForEmpresaViewProps = {
    className?: string
    scope: OverlayEmpresaScope
}

class FilterForEmpresaViewClass implements FCClassContext<FilterForEmpresaViewProps> {
    scope!: OverlayEmpresaScope

    // :: Methods

    render({ className, scope }: FilterForEmpresaViewProps) {
        this.scope = scope

        const classes = useStyles().classes

        // prettier-ignore
        return (
            <div className={clsx(classes.filterGroup, className)}>
                <Typography variant="h6" noWrap gutterBottom>
                    Empresa
                </Typography>
                {scope.cnpjs && scope.cnpjs.map((s) => <FilterEntryView key={s.id} tag="CNPJ" scope={s} />)}
                {scope.empresas && scope.empresas.map((s) => <FilterEntryView key={s.id} tag="Nome" scope={s} />)}
                {scope.cnaes && scope.cnaes.map((s) => <FilterEntryView key={s.id} tag="CNAE" scope={s} />)}
                {scope.empNatureza && scope.empNatureza.map((s) => <FilterEntryView key={s.id} tag="Nat.Jurídica" scope={s} />)}
                {scope.empEnderecos && scope.empEnderecos.map((s) => <FilterEntryView key={s.id} tag="Endereço" scope={s} />)}
                {scope.empLogradouros && scope.empLogradouros.map((s) => <FilterEntryView key={s.id} tag="Rua" scope={s} />)}
                {scope.empBairros && scope.empBairros.map((s) => <FilterEntryView key={s.id} tag="Bairro" scope={s} />)}
                {scope.empMunicipios && scope.empMunicipios.map((s) => <FilterEntryView key={s.id} tag="Cidade" scope={s} />)}
                {scope.empCeps && scope.empCeps.map((s) => <FilterEntryView key={s.id} tag="CEP" scope={s} />)}
            </div>
        )
    }
}
const FilterForEmpresaView = classToFComponent(FilterForEmpresaViewClass)

//////////////

type FilterForPessoaViewProps = {
    className?: string
    scope: OverlayPessoaScope
}

class FilterForPessoaViewClass implements FCClassContext<FilterForPessoaViewProps> {
    scope!: OverlayPessoaScope

    // :: Methods

    render({ className, scope }: FilterForPessoaViewProps) {
        this.scope = scope

        const classes = useStyles().classes

        // prettier-ignore
        return (
            <div className={clsx(classes.filterGroup, className)}>
                <Typography variant="h6" noWrap gutterBottom>
                    Pessoa
                </Typography>
                {scope.pessoas && scope.pessoas.map((s) => <FilterEntryView key={s.id} tag="Nome" scope={s} />)}
                {scope.pesEnderecos && scope.pesEnderecos.map((s) => <FilterEntryView key={s.id} tag="Endereço" scope={s} />)}
                {scope.pesLogradouros && scope.pesLogradouros.map((s) => <FilterEntryView key={s.id} tag="Rua" scope={s} />)}
                {scope.pesBairros && scope.pesBairros.map((s) => <FilterEntryView key={s.id} tag="Bairro" scope={s} />)}
                {scope.pesMunicipios && scope.pesMunicipios.map((s) => <FilterEntryView key={s.id} tag="Cidade" scope={s} />)}
                {scope.pesCeps && scope.pesCeps.map((s) => <FilterEntryView key={s.id} tag="CEP" scope={s} />)}
                {scope.pesCbos && scope.pesCbos.map((s) => <FilterEntryView key={s.id} tag="CBO" scope={s} />)}
            </div>
        )
    }
}
const FilterForPessoaView = classToFComponent(FilterForPessoaViewClass)

//////////////

type FilterForSocioViewProps = {
    className?: string
    scope: OverlaySocioScope
}

class FilterForSocioViewClass implements FCClassContext<FilterForSocioViewProps> {
    scope!: OverlaySocioScope

    // :: Methods

    render({ className, scope }: FilterForSocioViewProps) {
        this.scope = scope

        const classes = useStyles().classes

        return (
            <div className={clsx(classes.filterGroup, className)}>
                <Typography variant="h6" noWrap gutterBottom>
                    Sócio
                </Typography>
                {scope.socios && scope.socios.map((s) => <FilterEntryView key={s.id} tag="Nome" scope={s} />)}
            </div>
        )
    }
}
const FilterForSocioView = classToFComponent(FilterForSocioViewClass)

//////////////

type FilterForCnesViewProps = {
    className?: string
    scope: OverlayCnesScope
}

class FilterForCnesViewClass implements FCClassContext<FilterForCnesViewProps> {
    scope!: OverlayCnesScope

    // :: Methods

    render({ className, scope }: FilterForCnesViewProps) {
        this.scope = scope

        const classes = useStyles().classes

        // prettier-ignore
        return (
            <div className={clsx(classes.filterGroup, className)}>
                <Typography variant="h6" noWrap gutterBottom>
                    Estab. de Saúde
                </Typography>
                {scope.cnes && scope.cnes.map((s) => <FilterEntryView key={s.id} tag="CNES" scope={s} />)}
                {scope.cnesTipo && scope.cnesTipo.map((s) => <FilterEntryView key={s.id} tag="Tipo" scope={s} />)}
                {scope.cnesEnderecos && scope.cnesEnderecos.map((s) => <FilterEntryView key={s.id} tag="Endereço" scope={s} />)}
                {scope.cnesLogradouros && scope.cnesLogradouros.map((s) => <FilterEntryView key={s.id} tag="Rua" scope={s} />)}
                {scope.cnesBairros && scope.cnesBairros.map((s) => <FilterEntryView key={s.id} tag="Bairro" scope={s} />)}
                {scope.cnesMunicipios && scope.cnesMunicipios.map((s) => <FilterEntryView key={s.id} tag="Cidade" scope={s} />)}
                {scope.cnesCeps && scope.cnesCeps.map((s) => <FilterEntryView key={s.id} tag="CEP" scope={s} />)}
            </div>
        )
    }
}
const FilterForCnesView = classToFComponent(FilterForCnesViewClass)

//////////////

type FilterForPessoaAndSocioViewProps = {
    className?: string
    scope: OverlayPessoaAndSocioScope
}

class FilterForPessoaAndSocioViewClass implements FCClassContext<FilterForPessoaAndSocioViewProps> {
    scope!: OverlayPessoaAndSocioScope

    // :: Methods

    render({ className, scope }: FilterForPessoaAndSocioViewProps) {
        this.scope = scope

        const classes = useStyles().classes

        // prettier-ignore
        return (
            <div className={clsx(classes.filterGroup, className)}>
                <Typography variant="h6" noWrap gutterBottom>
                    Pessoa e Sócio
                </Typography>
                {scope.crms && scope.crms.map((s) => <FilterEntryView key={s.id} tag="CRM" scope={s} />)}
                {scope.crmUfs && scope.crmUfs.map((s) => <FilterEntryView key={s.id} tag="CRM UF" scope={s} />)}
                {scope.crmDataInscricao && scope.crmDataInscricao.map((s) => <FilterEntryView key={s.id} tag="CRM Dta Inscr." scope={s} />)}
                {scope.espMeds && scope.espMeds.map((s) => <FilterEntryView key={s.id} tag="Esp. Médica" scope={s} />)}
                {scope.cids && scope.cids.map((s) => <FilterEntryView key={s.id} tag="CID" scope={s} />)}
                {scope.atos && scope.atos.map((s) => <FilterEntryView key={s.id} tag="Ato" scope={s} />)}
                {scope.atoCbos && scope.atoCbos.map((s) => <FilterEntryView key={s.id} tag="CBO do Ato" scope={s} />)}
                {scope.cpfs && scope.cpfs.map((s) => <FilterEntryView key={s.id} scope={s} tag="Pessoa" />)}
            </div>
        )
    } //
}
const FilterForPessoaAndSocioView = classToFComponent(FilterForPessoaAndSocioViewClass, React)

//////////////

type FilterForOperadoraViewProps = {
    className?: string
    scope: OverlayOperadoraScope
}

class FilterForOperadoraViewClass implements FCClassContext<FilterForOperadoraViewProps> {
    scope!: OverlayOperadoraScope

    // :: Methods

    render({ className, scope }: FilterForOperadoraViewProps) {
        this.scope = scope

        const classes = useStyles().classes

        return (
            <div className={clsx(classes.filterGroup, className)}>
                <Typography variant="h6" noWrap gutterBottom>
                    Pessoa e Sócio
                </Typography>
                {scope.empOps && scope.empOps.map((s) => <FilterEntryView key={s.id} tag="Oper. Empresa" scope={s} />)}
                {scope.prfOps && scope.prfOps.map((s) => <FilterEntryView key={s.id} tag="Oper. Médico" scope={s} />)}
            </div>
        )
    }
}
const FilterForOperadoraView = classToFComponent(FilterForOperadoraViewClass)
