import { Places, ParamIds } from '../../Constants'
import { MainKeys } from '../../main/Main.keys'

export class TheConsumerMarketKeys extends MainKeys {
    get place() {
        return Places.theConsumerMarket
    }

    get selectedListingTab() {
        return this._intent.getParameterAsString(ParamIds.tmListingTab)
    }

    set selectedListingTab(value: string | undefined) {
        if (value && value !== 'none') {
            this._intent.setParameter(ParamIds.tmListingTab, value)
        } else {
            this._intent.setParameter(ParamIds.tmListingTab, undefined)
        }
    }
}
