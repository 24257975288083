import React from 'react'
import { SvgIconProps } from '@mui/material/SvgIcon'
import LifeshubLogo from './LogoForLifeshub'
import FetraLogo from './LogoForFetra'
import OliscorpLogo from './LogoForOliscorp'

export type ApplicationLogoProps = Omit<SvgIconProps, 'viewBox' | 'fill'> & {
    textColor?: string
    place?: 'sign-in' | 'app-bar'
}

let ApplicationLogo = LifeshubLogo

switch (location.hostname) {
    case 'fetra.lifeshub.com.br':
        ApplicationLogo = FetraLogo
        break
	case 'oliscorp.lifeshub.com.br':
        ApplicationLogo = OliscorpLogo
        break
}

export default ApplicationLogo
