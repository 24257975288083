import * as React from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'

import { Logger, NOOP_VOID } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import { ListagemTabsScope, type ListagemTabsOption } from '../tcm_scopes'

import ListagemEmpresaView from './tcm_listagem_empresa'
import QsaListagemView from './tcm_listagem_qsa'
import PrfListagemView from './tcm_listagem_prf'
import PesListagemView from './tcm_listagem_pes'

const LOG = Logger.get('TCM-VIEW-LIST-TABS')

const tabIdxByTabKey = new Map<number, ListagemTabsOption>()
const tabKeyByTabIdx = new Map<ListagemTabsOption, number>()

const useStyles = makeStyles()({
    view: {
        display: 'flex',
        flexDirection: 'column'
    },
    tab_content: {
        display: 'flex',
        flexDirection: 'row'
    }
})

export type EmpresaTableViewProps = {
    className?: string
    style?: React.CSSProperties
    scope: ListagemTabsScope
}

export class ListagemTabsViewClass implements FCClassContext<EmpresaTableViewProps> {
    scope!: ListagemTabsScope
    classes!: ReturnType<typeof useStyles>['classes']

    onSyncState(props: EmpresaTableViewProps) {
        initializeStatics()

        this.scope = props.scope
        this.classes = useStyles().classes
    }

    render({ className }: EmpresaTableViewProps): JSX.Element {
        const { scope, classes } = this
        const tabIdx = tabKeyByTabIdx.get(scope.active) ?? 0

        return (
            <Box className={clsx(classes.view, className)}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tabIdx} onChange={this.handleTabChange} aria-label="Listagens">
                        <Tab label="Empresas" {...a11yProps(0)} />
                        <Tab label="Sócios" {...a11yProps(1)} />
                        <Tab label="Médicos" {...a11yProps(2)} />
                        <Tab label="Vínculos" {...a11yProps(3)} />
                    </Tabs>
                </Box>
                <div className={classes.tab_content}>
                    {tabIdx === 0 && <ListagemEmpresaView scope={scope.empresasListagem} />}
                    {tabIdx === 1 && <QsaListagemView scope={scope.qsaListagem} />}
                    {tabIdx === 2 && <PrfListagemView scope={scope.prfListagem} />}
                    {tabIdx === 3 && <PesListagemView scope={scope.pesListagem} />}
                </div>
            </Box>
        )
    }

    readonly handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
        const key = tabIdxByTabKey.get(newValue) ?? 'pj'
        this.scope.onSelect(key).catch(LOG.caught)
    }
}

let initializeStatics = () => {
    const add = (idx: number, key: ListagemTabsOption) => {
        tabIdxByTabKey.set(idx, key)
        tabKeyByTabIdx.set(key, idx)
    }
    add(0, 'pj')
    add(1, 'qsa')
    add(2, 'prf')
    add(3, 'pes')

    initializeStatics = NOOP_VOID
}

export default classToFComponent(ListagemTabsViewClass, React)

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    }
}
