import React from 'react'
import { createTheme as muiCreateTheme } from '@mui/material'

import { LocaleProvider } from 'src/utils/views'
import { TssCacheProvider } from 'tss-react'
import { default as emotionCreateCache } from '@emotion/cache'
import { CacheProvider as EmotionCacheProvider } from '@emotion/react'
import { ThemeProvider } from '@mui/material/styles'
import { ContainerContext } from 'src/utils/views/useContainer'

import Colors from 'src/utils/views/colors'

export const createEmotionStyleElement = () => {
    const sheet = document.createElement('style')
    sheet.innerHTML = [
        '*::-webkit-scrollbar { width: 0.4em; height: 0.4em; }',
        '*::-webkit-scrollbar-thumb { border-radius: 0.5rem; background-color: #43A047; }',
        '*::-webkit-scrollbar-track { -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 0%); }'
    ].join('\n')
    return sheet
}

export const getOrCreateTheme = (() => {
    let getTheme = () => {
        const locale = LocaleProvider.get()

        const theme = muiCreateTheme(
            {
                typography: {
                    fontSize: 12
                },
                palette: {
                    mode: 'light',
                    primary: Colors.primary
                }
            },
            locale.mui
        )

        getTheme = () => theme

        return theme
    }

    return getTheme
})()

export type MuiThemeProps = {
    children?: React.ReactNode
}

export function MuiTheme({ children }: MuiThemeProps) {
    const theme = getOrCreateTheme()
    const containerEl = React.useMemo(() => document.body, [])
    const emotionEl = React.useMemo(createEmotionStyleElement, [])
    const styleCache = React.useMemo(() => {
        const headeElm = document.querySelector('head')
        if (headeElm) {
            headeElm.appendChild(emotionEl)
        } else {
            containerEl.insertBefore(emotionEl, containerEl.firstChild)
        }

        return emotionCreateCache({
            key: 'mui',
            prepend: true,
            container: emotionEl
        })
    }, [])

    return (
        <EmotionCacheProvider value={styleCache}>
            <TssCacheProvider value={styleCache}>
                <ThemeProvider theme={theme}>
                    <ContainerContext.Provider value={containerEl}>{children}</ContainerContext.Provider>
                </ThemeProvider>
            </TssCacheProvider>
        </EmotionCacheProvider>
    )
}
