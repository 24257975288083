import React from 'react'
import * as lodash from 'lodash'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'

import TcmCard from './helpers/tcm-card'
import { MapCardScope, SliderScope } from '../tcm_scopes'

import Divider from '@mui/material/Divider'
import Slider from '@mui/material/Slider'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'

import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import DeleteSweep from '@mui/icons-material/DeleteSweep'
import RouteIcon from '@mui/icons-material/Route'
import CropIcon from '@mui/icons-material/Crop'
import TripOriginIcon from '@mui/icons-material/TripOrigin'
import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'

import * as L from 'leaflet'
import { MapContainer, TileLayer } from 'react-leaflet'

const LOG = Logger.get('TCM-VIEW-MAP-CARD')

const ToolButtonCssCfg: React.CSSProperties = {
    border: '2px solid rgba(0,0,0,0.2)',
    borderRadius: 4
}

const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        maxWidth: 2400
    },
    map: {
        display: 'flex',
        flexGrow: 1
    },
    toolButton: {
        width: 34,
        height: 34,
        cursor: 'pointer',
        backgroundColor: 'white',
        borderLeft: ToolButtonCssCfg.border,
        borderRight: ToolButtonCssCfg.border,
        '&:hover': {
            backgroundColor: '#f4f4f4'
        }
    },
    topToolButton: {
        borderTop: ToolButtonCssCfg.border,
        borderTopLeftRadius: ToolButtonCssCfg.borderRadius,
        borderTopRightRadius: ToolButtonCssCfg.borderRadius,
    },
    bottomToolButton: {
        borderBottom: ToolButtonCssCfg.border,
        borderBottomLeftRadius: ToolButtonCssCfg.borderRadius,
        borderBottomRightRadius: ToolButtonCssCfg.borderRadius,
    },
    expandButton: {
        position: 'absolute',
        top: 5,
        right: 5,
        zIndex: 1001
    }
})

const MapToolbar = styled('div')({
    position: 'absolute',
    top: 10,
    left: 13,
    zIndex: 1100,
    boxShadow: 'none',
    backgroundClip: 'padding-box',
    background: 'transparent',
    // layout
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch'
})

const MapToolbarGap = styled('div')({
    marginTop: 5
})

export type MapCardViewProps = {
    className?: string
    style?: React.CSSProperties
    scope: MapCardScope
}

class MapCardViewClass implements FCClassContext<MapCardViewProps> {
    // :: State

    scope!: MapCardScope
    mapRef: L.Map | null = null
    mapResizeObserver = new ResizeObserver(this.onMapResize.bind(this))
    paperEl: HTMLDivElement | null = null

    // :: Emissors

    readonly emitOnPaperAttached = (paperEl: HTMLDivElement | null) => {
        this.paperEl && this.mapResizeObserver.unobserve(this.paperEl)
        this.paperEl = paperEl
        this.paperEl && this.mapResizeObserver.observe(this.paperEl)
    }

    readonly emitExpand = () => this.scope.onExpandClick().catch(LOG.caught)
    readonly emitShrunk = () => this.scope.onShrunkClick().catch(LOG.caught)
    readonly emitClearFilters = () => this.scope.onClearFilters().catch(LOG.caught)
    readonly emitZoomIn = () => (this.mapRef && this.mapRef.zoomIn(1), void 0)
    readonly emitZoomOut = () => (this.mapRef && this.mapRef.zoomOut(1), void 0)
    readonly emitSetRouteMode = () => this.scope.onSetRouteMode().catch(LOG.caught)
    readonly emitSetAreaMode = () => this.scope.onSetAreaMode().catch(LOG.caught)
    readonly emitSetCircleMode = () => this.scope.onSetCircleMode().catch(LOG.caught)

    readonly emitMapChanged = (map: L.Map | null) => {
        if (this.mapRef !== map) {
            this.mapRef = map
            this.scope.onMapChanged(map).catch(LOG.caught)
        }
    }

    // :: Methods

    onDetach() {
        this.paperEl && this.mapResizeObserver.unobserve(this.paperEl)
        this.mapResizeObserver.disconnect()
    }

    onSyncState({ scope }: MapCardViewProps) {
        this.scope = scope
    }

    onMapResize() {
        this.mapRef && this.mapRef.invalidateSize()
    }

    render({ className, style, scope }: MapCardViewProps) {
        const classes = useStyles().classes

        const topToolButton = clsx(classes.toolButton, classes.topToolButton)
        const bottomToolButton = clsx(classes.toolButton, classes.bottomToolButton)
        const singleToolButton = clsx(classes.toolButton, classes.topToolButton, classes.bottomToolButton)

        return (
            <TcmCard caption={scope.caption} className={className} style={style}>
                <MapToolbar>
                    <Tooltip title="Aproxima">
                        <AddIcon className={topToolButton} onClick={this.emitZoomIn} />
                    </Tooltip>
                    <Divider />
                    <Tooltip title="Afasta">
                        <RemoveIcon className={bottomToolButton} onClick={this.emitZoomOut} />
                    </Tooltip>
                    <MapToolbarGap />
                    <Tooltip title="Modo de seleção por rota">
                        <RouteIcon
                            className={topToolButton}
                            color={scope.mode == 'route' ? 'primary' : undefined}
                            onClick={this.emitSetRouteMode}
                        />
                    </Tooltip>
                    <Divider />
                    <Tooltip title="Modo de seleção por área">
                        <CropIcon
                            className={classes.toolButton}
                            color={scope.mode == 'polygon' ? 'primary' : undefined}
                            onClick={this.emitSetAreaMode}
                        />
                    </Tooltip>
                    <Divider />
                    <Tooltip title="Modo de seleção por círculo">
                        <TripOriginIcon
                            className={bottomToolButton}
                            color={scope.mode == 'circle' ? 'primary' : undefined}
                            onClick={this.emitSetCircleMode}
                        />
                    </Tooltip>
                    {scope.showClearButton && (
                        <>
                            <MapToolbarGap />
                            <Tooltip title="Remove filtros aplicados ao mapa">
                                <DeleteSweep className={singleToolButton} onClick={this.emitClearFilters} />
                            </Tooltip>
                        </>
                    )}
                </MapToolbar>
                <div ref={this.emitOnPaperAttached} className={classes.view}>
                    <MapContainer
                        className={classes.map}
                        zoomControl={false}
                        scrollWheelZoom={true}
                        ref={this.emitMapChanged}
                    >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    </MapContainer>
                </div>
                {scope.slider.visible && <CustomSlider scope={scope.slider} />}
                <IconButton
                    className={classes.expandButton}
                    edge="start"
                    color="inherit"
                    aria-label="expand card"
                    onClick={scope.expanded ? this.emitShrunk : this.emitExpand}
                >
                    {scope.expanded && <CloseFullscreenRoundedIcon />}
                    {!scope.expanded && <LaunchRoundedIcon />}
                </IconButton>
            </TcmCard>
        )
    }
}
export const MapCardView = classToFComponent(MapCardViewClass, React)
export default MapCardView

// :: Internal

const StyledSlider = styled(Slider)({
    zIndex: 1100
})

export type CustomSliderProps = {
    className?: string
    style?: React.CSSProperties
    scope: SliderScope
}

const CustomSlider = classToFComponent(
    class CustomSliderClass implements FCClassContext<CustomSliderProps> {
        scope!: SliderScope

        render({ className, style, scope }: CustomSliderProps): JSX.Element {
            this.scope = scope
            return (
                <StyledSlider
                    className={className}
                    style={style}
                    size="small"
                    aria-label="Small"
                    valueLabelDisplay="auto"
                    value={scope.value}
                    min={scope.min}
                    max={scope.max}
                    step={scope.step}
                    valueLabelFormat={this.formatSliderLabel}
                    onChange={this.emitKmSliderChange}
                />
            )
        }

        readonly emitKmSliderChange = (_event: Event, value: number | number[]) => {
            if (lodash.isNumber(value)) {
                this.scope.onValueChanged(value)
            }
        }

        readonly formatSliderLabel = (value: number) => {
            return `${value}${this.scope.unit}`
        }
    },
    React
)
