import React from 'react'
import clsx from 'clsx'
import { bindUpdate } from 'wdc-cube-react'

import { makeStyles } from 'tss-react/mui'

import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import FormGroup from '@mui/material/FormGroup'

import Colors from 'src/utils/views/colors'
import ApplicationLogo from 'src/components/icons/ApplicationLogo'

import { ResetPasswordScope } from '../Main.scopes'

export const usseStyles = makeStyles()({
    view: {
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'stretch',
        flexGrow: 1,
        justifyContent: 'center'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center'
    },
    logo: {
        width: 'unset',
        height: '64px',
        marginBottom: '32px'
    },
    card: {
        minWidth: '472px',
        padding: '24px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px'
    },
    title: {
        fontSize: '1.125rem',
        fontWeight: 500,
        lineHeight: 1.25,
        alignSelf: 'center',
        textAlign: 'center',
        marginBottom: '16px'
    }
})

export type ResetPasswordViewProps = {
    className?: string
    scope: ResetPasswordScope
}

export function ResetPasswordView({ className, scope }: ResetPasswordViewProps) {
    bindUpdate(React, scope)

    const handleEmailChanged = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => (scope.email = e.target.value),
        [scope]
    )

    const { classes } = usseStyles()

    return (
        <div className={clsx(classes.view, className)}>
            <div className={classes.content}>
                <ApplicationLogo className={classes.logo} place="sign-in" textColor={Colors.primary.main} />
                <Card className={classes.card}>
                    <Typography variant="h1" className={classes.title}>
                        Recuperar senha
                    </Typography>
                    <TextField
                        label="Email"
                        helperText={scope.emailError}
                        error={!!scope.emailError}
                        onChange={handleEmailChanged}
                    />
                    <FormGroup>
                        <Button variant="contained" onClick={scope.onResetPassword}>
                            Recuperar senha
                        </Button>
                        <Button variant="text" onClick={scope.onBackToLogin}>
                            Voltar ao login
                        </Button>
                    </FormGroup>
                </Card>
            </div>
        </div>
    )
}
