import React from 'react'
import clsx from 'clsx'
import { Logger } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { makeStyles } from 'tss-react/mui'
import { type CompanySortModel } from '../ta_types'
import { CompanyCardScope, type CompanyRow } from '../ta_scopes'
import { formatCNPJ } from 'src/utils/formatter-utils'

import LaunchRoundedIcon from '@mui/icons-material/LaunchRounded'
import CloseFullscreenRoundedIcon from '@mui/icons-material/CloseFullscreenRounded'
import DownloadIcon from '@mui/icons-material/Download'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import {
    DataGridPro,
    GridColDef,
    GridRowParams,
    GridValueFormatterParams,
    GridSortModel,
    DataGridProProps
} from '@mui/x-data-grid-pro'
import TablePagination from '@mui/material/TablePagination'
import LinearProgress from '@mui/material/LinearProgress'

const LOG = Logger.get('TA-VIEW-CO-CARD')

const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        padding: 10,
        alignSelf: 'stretch',
        flex: 1
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10
    },
    hSpacer: {
        flexGrow: 1
    },
    table: {
        alignSelf: 'stretch',
        flex: 1,
        flexBasis: 300
    }
})

const columns = static_buildColumns()

const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 75, 100, 500]

export type CompanyCardViewProps = {
    className?: string
    scope: CompanyCardScope
}

class CompanyCardViewClass implements FCClassContext<CompanyCardViewProps> {
    // :: Fields

    scope!: CompanyCardScope
    dataGrid: Partial<DataGridProProps> = {
        initialState: {
            sorting: {
                sortModel: []
            }
        },
        pageSizeOptions: [100]
    }

    // :: Emissors

    readonly emitExpandClick = () => this.scope.onExpandClick().catch(LOG.caught)
    readonly emitShrunkClick = () => this.scope.onShrunkClick().catch(LOG.caught)
    readonly emitDownloadClick = () => this.scope.onDownloadClick().catch(LOG.caught)

    readonly emitRowsPerPageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const pageSize = parseInt(e.target.value, 10)
        this.scope.onPageSizeChange(pageSize).catch(LOG.caught)
    }

    readonly emitChangePage = (__e: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        this.scope.onPageChange(newPage).catch(LOG.caught)
    }

    readonly emitRowClick = (params: GridRowParams<CompanyRow>) => {
        this.scope.onRowClick(params.row).catch(LOG.caught)
    }

    readonly emitSortModelChange = (gridSortModel: GridSortModel) => {
        const data: CompanySortModel[] = []
        for (const item of gridSortModel) {
            data.push({
                field: item.field as CompanySortModel['field'],
                sort: item.sort ?? 'asc'
            })
        }
        this.scope.onSortModelChange(data).catch(LOG.caught)
    }

    // :: Methods

    onSyncState({ scope }: CompanyCardViewProps) {
        this.scope = scope

        // Evita repintar a grade por motivo de diferenca de atributo
        if (this.dataGrid.initialState?.sorting?.sortModel !== scope.sortBy) {
            this.dataGrid.initialState = {
                sorting: {
                    sortModel: scope.sortBy
                }
            }
        }

        if (this.dataGrid.pageSizeOptions?.at(0) !== scope.pageSize) {
            this.dataGrid.pageSizeOptions = [scope.pageSize]
        }
    }

    render({ className, scope }: CompanyCardViewProps) {
        const classes = useStyles().classes

        return (
            <Paper className={clsx(classes.view, className)}>
                <div className={classes.title}>
                    <Typography variant="h6" gutterBottom>
                        Empresas e Estabelecimentos com Vínculo
                    </Typography>
                    <span className={classes.hSpacer} />
                    {scope.downloadEnabled && (
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={this.emitDownloadClick}
                        >
                            <DownloadIcon />
                        </IconButton>
                    )}
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={scope.expanded ? this.emitShrunkClick : this.emitExpandClick}
                    >
                        {scope.expanded && <CloseFullscreenRoundedIcon />}
                        {!scope.expanded && <LaunchRoundedIcon />}
                    </IconButton>
                </div>
                <DataGridPro
                    className={classes.table}
                    rows={scope.rows}
                    columns={columns}
                    sortingMode="server"
                    paginationMode="client"
                    pageSizeOptions={this.dataGrid.pageSizeOptions}
                    getRowId={static_getRowId}
                    disableVirtualization
                    hideFooter
                    hideFooterPagination
                    disableColumnFilter={true}
                    onRowClick={this.emitRowClick}
                    onSortModelChange={this.emitSortModelChange}
                    initialState={this.dataGrid.initialState}
                />
                {scope.working && <LinearProgress color="secondary" />}
                {scope.total > scope.pageSize && (
                    <TablePagination
                        component="div"
                        count={scope.total}
                        page={scope.page}
                        onPageChange={this.emitChangePage}
                        rowsPerPage={scope.pageSize}
                        rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
                        onRowsPerPageChange={this.emitRowsPerPageChange}
                    />
                )}
            </Paper>
        )
    }
}
export const CompanyCardView = classToFComponent(CompanyCardViewClass, React)
export default CompanyCardView

function static_getRowId(row: CompanyRow) {
    const cnes = row.cnes ?? '0000000'
    const cnpj = row.cnpj ?? '00000000000000'
    return `${cnes}-${cnpj}`
}

function static_buildColumns(): GridColDef[] {
    return [
        {
            field: 'cnes',
            headerName: 'CNES',
            width: 80
        },
        {
            field: 'cnpj',
            headerName: 'CNPJ',
            width: 160,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                return params.value ? formatCNPJ(params.value) : ''
            }
        },
        {
            field: 'nome',
            headerName: 'Nome',
            width: 250
        },
        {
            field: 'cnae',
            headerName: 'CNAE',
            width: 80
        },
        {
            field: 'descricaoCnae',
            headerName: 'Descrição CNAE',
            width: 250
        },
        {
            field: 'naturezaJuridicaDesc',
            headerName: 'Natureza Juridica / Agrupamento',
            width: 300
        },
        {
            field: 'esTipoNome',
            headerName: 'Tipo Estab. Saúde',
            width: 250
        },
        {
            field: 'endereco',
            headerName: 'Endereço',
            width: 500
        },
        {
            field: 'enderecoMunicipio',
            headerName: 'Município',
            width: 120
        },
        {
            field: 'siglaUF',
            headerName: 'UF',
            width: 40
        },
        {
            field: 'qtdeFuncionarios',
            headerName: 'Funcionários',
            width: 120,
            type: 'number'
        }
    ]
}
