import { TcmPresenterForStringKeywordFilter } from './tcm_filter_presenter_for_string_keyword'

import { Logger } from 'wdc-cube'
import {
    StringKeywordFilterScope,
    StringKeywordParcelFilterScope,
    JunctionMode,
    KeywordOption,
    FilterExtraCnpjScope
} from '../tcm_scopes'
import { TcmPresenterForFilterPane } from './tcm_presenter_filter_pane'
import { ValueProperty } from '../tcm_filter_manager'
import { EmpresaCodeFilter } from '../tcm_service'

const LOG = Logger.get('TCM-AEFilter')

export class TcmPresenterFilterForCnpj extends TcmPresenterForStringKeywordFilter {
    constructor(
        owner: TcmPresenterForFilterPane,
        scope: StringKeywordFilterScope,
        valueProperty: ValueProperty<EmpresaCodeFilter>,
        uppercase?: boolean,
        onSearch?: (mode: JunctionMode, text: string) => Promise<KeywordOption[]>
    ) {
        super(owner, scope, valueProperty, uppercase, onSearch)
    }

    addConjuntion(): StringKeywordParcelFilterScope {
        const andScope = super.addConjuntion()
        const extraScope = new FilterExtraCnpjScope()
        extraScope.onModeChange = this._handleOnModeChanged.bind(this, extraScope)
        extraScope.update = this.update
        andScope.extra = extraScope
        return andScope
    }

    addDisjuntion(): StringKeywordParcelFilterScope {
        const orScope = super.addDisjuntion()
        const extraScope = new FilterExtraCnpjScope()
        extraScope.onModeChange = this._handleOnModeChanged.bind(this, extraScope)
        extraScope.update = this.update
        orScope.extra = extraScope
        return orScope
    }

    protected async _handleOnModeChanged(extraScope: FilterExtraCnpjScope, value: boolean) {
        extraScope.cloning = value
        LOG.debug(`_handleOnModeChanged(value=${value})`)
    }

    protected populateWithExtraValues(filter: EmpresaCodeFilter, parcelScope: StringKeywordParcelFilterScope) {
        if (parcelScope.extra) {
            const extraScope = parcelScope.extra as FilterExtraCnpjScope
            filter.cloning = extraScope.cloning
        }
        return filter
    }
}
