import * as L from 'leaflet'
import * as geojson from 'geojson'

import { Logger, Presenter, NOOP_VOID } from 'wdc-cube'
import { MapCardScope, ScopeDefaults } from '../tcm_scopes'
import { TheConsumerMarketPresenter } from '../tcm_presenter'
import { type GeoLocation, type EmpresaLocationBean } from '../tcm_service'
import FilterManager from '../tcm_filter_manager'
import * as utils from './utils'

import { TheConsumerMarketService, type EmpresaFilter, type FetchRequest, type FetchResponse } from '../tcm_service'
import LMapClickSimulation from './lmap_click_simulation'
import BaseSelectionModeBehaviour from './selection_mode_behaviour'
import AreaSelectionModeBehaviour from './selection_mode_behaviour_area'
import RouteSelectionModeBehaviour from './selection_mode_behaviour_route'
import CircleSelectionModeBehaviour from './selection_mode_behaviour_circle'

const LOG = Logger.get('TcmPresenterForMap')

const service = TheConsumerMarketService.singleton()

const MARK_CIRCLE_CLASSES = static_buildMarkCircleClasses()

type MapPointEntry = {
    coordenadas: L.LatLngLiteral
    empresas: EmpresaLocationBean[]
}

let ENCHENTE_RS_2024_FEATURE: geojson.GeoJsonObject | null = null

export class TcmPresenterForMap extends Presenter<MapCardScope, TheConsumerMarketPresenter> {
    // Constructor

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.mapa)
        this.__parent = owner
        this.__filterManager = owner.filterManager

        this.__areaSelectionBehaviour = new AreaSelectionModeBehaviour()
        this.__areaSelectionBehaviour.owner = this

        this.__routeSelectionBehaviour = new RouteSelectionModeBehaviour()
        this.__routeSelectionBehaviour.owner = this

        this.__circleSelectionBehaviour = new CircleSelectionModeBehaviour()
        this.__circleSelectionBehaviour.owner = this

        this.__selecionBehaviour = this.__routeSelectionBehaviour

        owner.scope.filterPane.enchenteRs2024.onNotifyMap = this.__enchenteRs2024Changed.bind(this)
    }

    // Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __filterManager: FilterManager
    private __map: L.Map | null = null
    private __mapCenter = ScopeDefaults.getDefaultCenter()
    private __layerGroup: L.LayerGroup<unknown> | null = null
    private __loaded = false
    private __fetching = false
    private __dataChangedDuringFetch = false
    private __fetchRequestCount = 0
    private __requestHash = ''
    private __boundHash = ''
    private __dataset: EmpresaLocationBean[] = []
    private __clickSimulation = new LMapClickSimulation()
    private __removeEnchenteRs2024Layer = utils.staticNoopOther

    private readonly __areaSelectionBehaviour: AreaSelectionModeBehaviour
    private readonly __routeSelectionBehaviour: RouteSelectionModeBehaviour
    private readonly __circleSelectionBehaviour: CircleSelectionModeBehaviour
    private __selecionBehaviour: BaseSelectionModeBehaviour

    get filterManager() {
        return this.__filterManager
    }

    get keyboard() {
        return this.__parent.scope.keyboard
    }

    async initialize() {
        this.__clickSimulation.clickListener = this.__handleMapClickEvent.bind(this)

        this.scope.update = this.update
        this.scope.onMapChanged = this.__handleMapChanged.bind(this)
        this.scope.onClearFilters = this.__handleClearFilters.bind(this)
        this.scope.onSetAreaMode = this.__handleSetAreaMode.bind(this)
        this.scope.onSetRouteMode = this.__handleSetRouteMode.bind(this)
        this.scope.onSetCircleMode = this.__handleSetCircleMode.bind(this)
        this.scope.onExpandClick = this.__handleExpandClick.bind(this)
        this.scope.onShrunkClick = this.__handleShrunkClick.bind(this)

        this.scope.slider.update = this.update
        this.scope.slider.onValueChanged = this.__handleSliderValueChanged.bind(this)

        this.__selecionBehaviour.setMode(15)
    }

    override release(): void {
        this.__clickSimulation.unbind()
        this.__removeEnchenteRs2024Layer()
        super.release()
    }

    /**
     * Dispara sempre que um this.update() for invocado
     * Roda uma única vez por ciclo de atualizacao.
     *
     * Ideal para calcular valores derivados
     */
    override onBeforeScopeUpdate() {
        this.scope.showClearButton = this.hasFilter()
        this.__selecionBehaviour.onBeforeScopeUpdate()
    }

    isLoaded() {
        return this.__loaded
    }

    clear() {
        this.__boundHash = ''
        this.__requestHash = ''
        this.__areaSelectionBehaviour.clear()
        this.__routeSelectionBehaviour.clear()
        this.__circleSelectionBehaviour.clear()
        this.update()
    }

    hasFilter() {
        return (
            this.__areaSelectionBehaviour.hasFilter() ||
            this.__routeSelectionBehaviour.hasFilter() ||
            this.__circleSelectionBehaviour.hasFilter()
        )
    }

    getBounds(): GeoLocation[] {
        if (this.__map) {
            const bounds = this.__map.getBounds()
            return utils.twoLocationToReactablePolygon(
                {
                    lon: bounds.getWest(),
                    lat: bounds.getNorth()
                },
                {
                    lon: bounds.getEast(),
                    lat: bounds.getSouth()
                }
            )
        }

        // Fallback para Brasil
        return [
            {
                lon: 12.46876014482322,
                lat: -76.46484375000001
            },
            {
                lon: 12.46876014482322,
                lat: -28.4765625
            },
            {
                lon: -40.380028402511826,
                lat: -28.4765625
            },
            {
                lon: -40.380028402511826,
                lat: -76.46484375000001
            }
        ]
    }

    private async __handleExpandClick() {
        this.scope.expanded = true
        this.__parent.scope.maximizeCard = 'mapa'
    }

    private async __handleShrunkClick() {
        this.scope.expanded = false
        this.__parent.scope.maximizeCard = ''
    }

    private async __handleClearFilters() {
        if (this.keyboard.ctrlKey) {
            this.__boundHash = ''
            this.__requestHash = ''
            this.__selecionBehaviour.clear()
        } else if (this.__selecionBehaviour.hasFilter()) {
            this.clear()
        }
        this.update()
        this.__renderElements(true)
    }

    private async __handleSetAreaMode() {
        const zoomValue = this.__map ? this.__map.getZoom() : 4
        this.__selecionBehaviour = this.__areaSelectionBehaviour
        this.__selecionBehaviour.setMode(zoomValue)
        this.__selecionBehaviour.onBeforeScopeUpdate()
        this.update()
    }

    private async __handleSetRouteMode() {
        const zoomValue = this.__map ? this.__map.getZoom() : 4
        this.__selecionBehaviour = this.__routeSelectionBehaviour
        this.__selecionBehaviour.setMode(zoomValue)
        this.__selecionBehaviour.onBeforeScopeUpdate()
        this.update()
    }

    private async __handleSetCircleMode() {
        const zoomValue = this.__map ? this.__map.getZoom() : 4
        this.__selecionBehaviour = this.__circleSelectionBehaviour
        this.__selecionBehaviour.setMode(zoomValue)
        this.__selecionBehaviour.onBeforeScopeUpdate()
        this.update()
    }

    private async __handleSliderValueChanged(value: number) {
        this.scope.slider.value = value
        if (this.__fetching) {
            this.__dataChangedDuringFetch = true
            this.__fetchRequestCount++
        } else {
            const mgr = this.__filterManager
            this.__selecionBehaviour.applyFilter(mgr)
            if (this.__selecionBehaviour.hasFilter()) {
                await this.__renderElements(true)
            }
        }
    }

    private async __handleMapChanged(map: L.Map | null) {
        const changed = this.__map !== map
        if (this.__map && changed) {
            this.__removeEnchenteRs2024Layer()
            this.__clickSimulation.unbind()
            this.__map.off('moveend')
            this.__map.off('zoomend')
            this.__selecionBehaviour.removeAllLayers()
        }

        this.__map = map
        if (map && changed) {
            this.__clickSimulation.bind(map)

            this.__selecionBehaviour.setZoom(map.getZoom())

            map.on('moveend', this.__onMapMoveEndEvent.bind(this))
            map.on('zoomend', this.__handleZoomEndEvent.bind(this))

            if (!this.__mapCenter) {
                this.__mapCenter = ScopeDefaults.getDefaultCenter()
            }
            if (this.__layerGroup) {
                this.__layerGroup.addTo(map)
                map.setView(this.__mapCenter, 4)
            } else {
                map.setView(this.__mapCenter, 4)
            }

            if (this.__dataset.length > 0) {
                this.plotPlaces()
            }

            this.__enchenteRs2024Changed()
            this.__areaSelectionBehaviour.draw(map)
            this.__routeSelectionBehaviour.draw(map)
            this.__circleSelectionBehaviour.draw(map)
        }
    }

    async __enchenteRs2024Changed() {
        const map = this.__map
        if (!map) return

        this.__removeEnchenteRs2024Layer()

        const enchenteRs2024Active = this.owner.scope.filterPane.enchenteRs2024.active
        if (enchenteRs2024Active) {
            try {
                if (!ENCHENTE_RS_2024_FEATURE) {
                    const geoResp = await fetch('data/inundacao_em_6-8_de_maio_de_2024.json', {
                        method: 'GET'
                    })

                    ENCHENTE_RS_2024_FEATURE = await geoResp.json()
                }

                if (ENCHENTE_RS_2024_FEATURE) {
                    const layer = L.geoJSON(ENCHENTE_RS_2024_FEATURE, {
                        style: {
                            color: utils.REGION_COLOR
                        }
                    }).addTo(map)
                    this.__removeEnchenteRs2024Layer = () => {
                        layer.removeFrom(map)
                        this.__removeEnchenteRs2024Layer = utils.staticNoopOther
                    }
                }
            } catch (e) {
                LOG.error('Reading GEOJSON', e)
            }
        }
    }

    plotPlaces() {
        this.scope.showClearButton = this.__selecionBehaviour.hasFilter()
        const leafletMap = this.__map
        if (!leafletMap) {
            return
        }
        const dataset = this.__dataset

        if (this.__layerGroup) {
            this.__layerGroup.remove()
            this.__layerGroup = null
        }
        const layerGroup = L.layerGroup()

        let center = ScopeDefaults.getDefaultCenter()
        const southWest: L.LatLngLiteral = { ...center }
        const northEast: L.LatLngLiteral = { ...center }

        const entryMap = new Map<string, MapPointEntry>()

        for (const row of dataset) {
            if (!row.endereco_location) continue

            const key = `${row.endereco_location.lat}:${row.endereco_location.lon}`
            let entry = entryMap.get(key)
            if (!entry) {
                entry = {
                    coordenadas: {
                        lat: row.endereco_location.lat,
                        lng: row.endereco_location.lon
                    },
                    empresas: []
                }
                entryMap.set(key, entry)
            }
            entry.empresas.push(row)
        }

        if (entryMap.size > 0) {
            const entryValuesIt = entryMap.values()[Symbol.iterator]()
            let entry = entryValuesIt.next()
            if (!entry.done) {
                const coordenadas = entry.value.coordenadas!
                northEast.lng = southWest.lng = coordenadas.lng
                northEast.lat = southWest.lat = coordenadas.lat

                center.lat = coordenadas.lat
                center.lng = coordenadas.lng
            }

            while (!entry.done) {
                const { lng, lat } = entry.value.coordenadas!

                southWest.lng = Math.min(southWest.lng, lng)
                northEast.lng = Math.max(northEast.lng, lng)

                northEast.lat = Math.min(northEast.lat, lat)
                southWest.lat = Math.max(southWest.lat, lat)

                const names = entry.value.empresas.map((e) => `${e.cnpj} - ${e.nome}`)

                let markClassName = MARK_CIRCLE_CLASSES[0]
                if (entry.value.empresas.length) {
                    markClassName = MARK_CIRCLE_CLASSES[1]
                } else if (entry.value.empresas.length > 0) {
                    markClassName = MARK_CIRCLE_CLASSES[2]
                }

                const marker = new L.Marker(
                    { lng, lat },
                    {
                        title: names.join('\n'),
                        icon: L.divIcon({ className: markClassName, iconSize: L.point(12, 12) })
                    }
                )
                layerGroup.addLayer(marker)

                entry = entryValuesIt.next()
            }
        }

        layerGroup.addTo(leafletMap)

        if (this.__boundHash === '') {
            if (entryMap.size === 1) {
                leafletMap.flyTo(center, 15)
            } else if (entryMap.size > 1) {
                const bounds = new L.LatLngBounds(southWest, northEast)
                center = bounds.getCenter()
                leafletMap.flyToBounds(bounds, { maxZoom: 15 })
            } else if (!this.__selecionBehaviour.hasFilter()) {
                leafletMap.flyTo(center, 4)
            }
        }

        this.__layerGroup = layerGroup
        this.__mapCenter = center
        this.__loaded = true
    }

    private __handleMapClickEvent(evt: L.LeafletMouseEvent) {
        this.__selecionBehaviour.addLocation({
            lat: evt.latlng.lat,
            lon: evt.latlng.lng
        })
        this.__renderElements(true).catch(LOG.caught)
    }

    private __handleZoomEndEvent() {
        if (this.__map) {
            this.__selecionBehaviour.setZoom(this.__map.getZoom())
        }
    }

    private async __onMapMoveEndEvent() {
        if (this.__selecionBehaviour.hasFilter()) {
            return
        }

        const bounds = this.getBounds()

        const newRequestHash = utils.buildHash(bounds)
        if (this.__boundHash !== newRequestHash) {
            this.__boundHash = newRequestHash
            if (!this.__selecionBehaviour.hasFilter()) {
                if (this.__fetching) {
                    this.__fetchRequestCount++
                } else {
                    const filter = this.__filterManager.build()
                    await this.fetch(filter)
                }
            }
        }
    }

    private __prepareFetch(request: FetchRequest) {
        const requestId = this.__fetchRequestCount

        let newRequestHash = this.__requestHash
        let newBoundHash = this.__boundHash

        let viewPortBounds: GeoLocation[] | undefined = undefined
        const bounds = this.getBounds()
        if (!this.hasFilter()) {
            viewPortBounds = bounds
        }

        newRequestHash = utils.buildHash({ request, listagem_localizacao: viewPortBounds })
        newBoundHash = utils.buildHash(bounds)

        if (newRequestHash !== this.__requestHash) {
            request.listagem_localizacao = viewPortBounds ? { viewPortBounds } : true

            return (resp: FetchResponse) => {
                this.__handleResponse(resp, requestId, newRequestHash, newBoundHash)
            }
        }

        return NOOP_VOID
    }

    async fetch(filter: EmpresaFilter) {
        try {
            this.__dataChangedDuringFetch = false
            this.__fetching = true

            const request: FetchRequest = { filter }
            const postAction = this.__prepareFetch(request)
            if (request.listagem_localizacao) {
                const resp = await service.fetch(request)
                postAction(resp)
            }
        } finally {
            this.__fetching = false
        }
    }

    private __handleResponse(resp: FetchResponse, requestId: number, newRequestHash: string, newBoundHash: string) {
        this.__dataset = resp.listagem_localizacao ?? []
        this.__requestHash = newRequestHash
        this.__boundHash = newBoundHash
        this.plotPlaces()

        if (requestId !== this.__fetchRequestCount) {
            this.__renderElements(this.__dataChangedDuringFetch).catch(LOG.caught)
        }
    }

    private async __renderElements(fetchData: boolean) {
        this.__boundHash = ''
        this.update()
        this.__selecionBehaviour.preparePoints()
        if (this.__map) {
            this.__selecionBehaviour.draw(this.__map)
        }

        const mgr = this.__filterManager

        this.__areaSelectionBehaviour.applyFilter(mgr)
        this.__routeSelectionBehaviour.applyFilter(mgr)
        this.__circleSelectionBehaviour.applyFilter(mgr)

        if (fetchData) {
            this.__dataChangedDuringFetch = false
            await this.__parent.fetchData('')
        }
    }
}

function static_buildMarkCircleClasses() {
    return [
        'gg-ue-red-circle',
        'gg-islamic-green-circle',
        'gg-duke-blue-circle',
        'gg-mughal-green-circle',
        'gg-weldon-blue-circle'
    ]
}
