import lodash from 'lodash'
import { Logger } from 'wdc-cube'
import { StringFilterScope, StringParcelFilterScope, JunctionMode, StringOperator } from '../tcm_scopes'
import { TcmPresenterForFilterPane } from './tcm_presenter_filter_pane'
import { ValueProperty, StringFilter } from '../tcm_filter_manager'
import { FilterPresenter } from './tcm_filter_types'

const LOG = Logger.get('TCM-StringFilter')

let UID_GEN = 0

export class TcmPresenterForStringFilter extends FilterPresenter<StringFilterScope, TcmPresenterForFilterPane> {
    // Constructor

    constructor(
        owner: TcmPresenterForFilterPane,
        scope: StringFilterScope,
        valueProperty: ValueProperty<StringFilter>,
        onSearch?: (text: string) => Promise<string[]>
    ) {
        super(owner, scope)
        this.valueProperty = valueProperty
        this.onSearch = onSearch
        this.fetch = lodash.debounce(() => {
            this.owner.owner.fetchData('').catch(LOG.caught)
        }, 150)
        this.onSearchDebounce = lodash.debounce(this.doSearch.bind(this), 150)
    }

    // :: Fields

    protected valueProperty: ValueProperty<StringFilter>
    protected fetch: lodash.DebouncedFuncLeading<() => void>
    protected onSearchDebounce: lodash.DebouncedFuncLeading<
        (parcelScope: StringParcelFilterScope, searchText: string) => void
    >
    protected onSearch?: (text: string) => Promise<string[]>

    // :: Getters and Setters

    get owner() {
        return super.owner as TcmPresenterForFilterPane
    }

    protected override async bindEvents() {
        this.scope.onAddFilter = this.handleAddFilter.bind(this)
        LOG.debug('initialized')
    }

    override release(): void {
        this.fetch.cancel()
    }

    override clear() {
        this.scope.conjunction.length = 0
        this.scope.disjunction.length = 0
        this.valueProperty.clear()
    }

    override publish() {
        this.valueProperty.clear()

        const emptyPredicate = (scope: StringParcelFilterScope) => lodash.trim(scope.value) === ''
        this.scope.conjunction.removeByCriteria(emptyPredicate)
        this.scope.disjunction.removeByCriteria(emptyPredicate)

        for (const parcelScope of [...this.scope.conjunction, ...this.scope.disjunction]) {
            const value = parcelScope.value.trim()
            if (!value) {
                continue
            }
            this.valueProperty.add(
                this.populateWithExtraValues(
                    {
                        mode: parcelScope.junctionMode,
                        operator: parcelScope.operator,
                        value
                    },
                    parcelScope
                )
            )
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected populateWithExtraValues(filter: StringFilter, _parcelScope: StringParcelFilterScope) {
        return filter
    }

    addConjuntion() {
        const andScope = new StringParcelFilterScope()
        andScope.uid = `and-${UID_GEN++}`
        andScope.junctionMode = JunctionMode.AND
        andScope.onOperatorChange = this.handleOperatorChange.bind(this, andScope)
        andScope.onValueChange = this.handleValueChange.bind(this, andScope)
        andScope.onClear = this.handleClear.bind(this, andScope)
        andScope.onEnterKeyPressed = this.handleEnterKeyPressed.bind(this)
        andScope.onOptionSelected = this.handleOptionSelected.bind(this, andScope)
        andScope.update = this.update
        this.scope.conjunction.push(andScope)
        return andScope
    }

    addDisjuntion() {
        const orScope = new StringParcelFilterScope()
        orScope.uid = `or-${UID_GEN++}`
        orScope.junctionMode = JunctionMode.OR
        orScope.onOperatorChange = this.handleOperatorChange.bind(this, orScope)
        orScope.onValueChange = this.handleValueChange.bind(this, orScope)
        orScope.onClear = this.handleClear.bind(this, orScope)
        orScope.onEnterKeyPressed = this.handleEnterKeyPressed.bind(this)
        orScope.onOptionSelected = this.handleOptionSelected.bind(this, orScope)
        orScope.update = this.update
        this.scope.disjunction.push(orScope)
        return orScope
    }

    private async handleAddFilter(mode: JunctionMode) {
        if (mode === JunctionMode.AND) {
            this.addConjuntion()
        } else {
            this.addDisjuntion()
        }
    }

    private async handleClear(parcelScope: StringParcelFilterScope) {
        let found = true

        let idx = this.scope.conjunction.findIndex((item) => item.uid === parcelScope.uid)
        if (idx !== -1) {
            this.scope.conjunction.removeByIndex(idx)
            found = true
        }

        idx = this.scope.disjunction.findIndex((item) => item.uid === parcelScope.uid)
        if (idx !== -1) {
            this.scope.disjunction.removeByIndex(idx)
            found = true
        }

        if (found && parcelScope.value) {
            this.fetch.cancel()
            this.fetch()
        }
    }

    private async handleOperatorChange(parcelScope: StringParcelFilterScope, value: StringOperator) {
        parcelScope.operator = value
        parcelScope.valueVisible = !(
            value === StringOperator.is_blank ||
            value === StringOperator.is_not_blank ||
            value === StringOperator.is_empty ||
            value === StringOperator.is_not_empty
        )
    }

    private async handleValueChange(parcelScope: StringParcelFilterScope, value: string) {
        parcelScope.value = value
        if (this.onSearch) {
            this.onSearchDebounce.cancel()
            this.onSearchDebounce(parcelScope, value.trim())
        }
    }

    private doSearch(parcelScope: StringParcelFilterScope, searchText: string) {
        if (!this.onSearch) {
            parcelScope.options.length > 0 && (parcelScope.options = [])
            return
        }

        if (searchText) {
            this.onSearch(searchText)
                .then((labels) => {
                    parcelScope.options = labels
                })
                .catch((e) => {
                    parcelScope.options = []
                    LOG.error(e.message)
                })
            return
        }

        parcelScope.options.length > 0 && (parcelScope.options = [])
    }

    private async handleOptionSelected(parcelScope: StringParcelFilterScope, value: string) {
        parcelScope.value = value
        parcelScope.options = []
    }

    private async handleEnterKeyPressed() {
        this.fetch.cancel()
        this.fetch()
    }
}
