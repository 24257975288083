import lodash from 'lodash'
import * as DateFnsNs from 'date-fns'
import { Logger, Presenter, action } from 'wdc-cube'
import * as lang from 'src/utils/lang'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { TablePRFScope } from './tcm_scopes'
import {
    TheConsumerMarketService,
    StringKeywordOperator,
    JunctionMode,
    type PRFRow,
    type EmpresaFilter,
    type ColumnSort,
    type ColumnFilter
} from './tcm_service'

type PaginationState = {
    pageIndex: number
    pageSize: number
}

const LOG = Logger.get('TcmPresenterForPRFListagem')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForPRFListagem extends Presenter<TablePRFScope, TheConsumerMarketPresenter> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.listagemTabs.prfListagem)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.caption = this.__texts.prfListagem
        this.scope.sorting = [{id: 'nome', desc: false}]
        this.scope.onSelectionChange = this.__onSelectionChange.bind(this)
        this.scope.onFiltrationChange = this.__onFiltrationChange.bind(this)
        this.scope.onPaginationChange = this.__onPaginationChange.bind(this)
        this.scope.onSortingChange = this.__onSortingChange.bind(this)
        this.scope.update = this.update
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.scope.pageIndex = 0
        this.scope.pageSize = 100
        this.owner.filterPane.socioNome.clear()
        this.__filterManager.socioCodigo.clear()
        this.__filterManager.socioNome.clear()
    }

    async fetch(
        filter: EmpresaFilter,
        cfg?: {
            paginationState?: PaginationState
            sorting?: ColumnSort[]
            columnFilters?: ColumnFilter[]
        }
    ) {
        let { paginationState, sorting, columnFilters } = cfg ?? {}
        this.scope.loaded = false
        try {
            if (!columnFilters) {
                columnFilters = this.scope.filters
            }

            if (!paginationState) {
                paginationState = {
                    pageIndex: 0,
                    pageSize: this.scope.pageSize
                }
            }

            if (!sorting) {
                sorting = this.scope.sorting
            }

            let search_after: unknown
            if (paginationState.pageIndex > 0) {
                const lastRow = this.scope.dataset[this.scope.dataset.length - 1]
                if (lastRow) {
                    const sortValues: unknown[] = []
                    for(const sortItem of sorting) {
                        sortValues.push((lastRow as Record<string, unknown>)[sortItem.id])
                    }
                    sortValues.push(lastRow.ipf)
                    search_after = sortValues
                }
            }

            const resp = await service.fetch({
                listagem_prf: {
                    sorting,
                    columnFilters,
                    search_after,
                    pageSize: paginationState.pageSize
                },
                filter: filter
            })

            this.scope.pageIndex = paginationState.pageIndex
            this.scope.pageSize = paginationState.pageSize
            this.scope.sorting = sorting

            const rowDataset: PRFRow[] = []
            const beanDataset = resp.listagem_prf ?? []
            for (let i = 0; i < beanDataset.length; i++) {
                const bean = beanDataset[i]

                rowDataset.push({
                    key: bean.key,
                    ipf: bean.ipf,
                    cpf: bean.cpf,
                    nome: bean.nome ?? '',
                    cnpj: bean.cnpj ?? '',
                    pj_nome: bean.pj_nome ?? '',
                    sexo: bean.sexo ?? '',
                    dt_nascimento: isoToDate(bean.dt_nascimento),
                    celular: bean.celular ?? '',
                    crm: bean.crm?.cd ?? '',
                    crm_inscricao_tipo: bean.crm?.inscricao?.cd ?? '',
                    crm_inscricao_data: isoToDate(bean.crm?.inscricao?.data),
                    crm_inscricao_data_primeira: isoToDate(bean.crm?.inscricao?.data_primeira),
                    crm_situacao: bean.crm?.situacao?.ds ?? '',
                    crm_telefones: bean.crm?.telefone?.length > 0 ? bean.crm.telefone.join('; ') : '',
                    crm_endereco: bean.crm?.endereco ?? '',
                    socio_qualificacoes: codDscArrayToString(bean.socio_qualificacao),
                    cbos: codDscArrayToString(bean.cbo)
                })
            }
            LOG.debug(`prf_dataset.length=${rowDataset.length}`)

            this.scope.rowCount = resp.listagem_prf_agg ? resp.listagem_prf_agg['prf-count'].value | 0 : 0
            this.scope.dataset = rowDataset
            this.scope.datasetVersion++

            this.__markSelected(undefined)
        } finally {
            this.scope.loaded = true
        }
    }

    private async __onSelectionChange(selectionMap: Map<string, boolean>) {
        const prfIdMap = new Map<string, boolean>()

        this.scope.dataset.forEach((row) => {
            if (selectionMap.get(row.key) === true) {
                prfIdMap.set(row.ipf, true)
            }
        })

        const prop = this.__filterManager.prfCodigo
        prop.clear()
        if (prfIdMap.size > 0) {
            prop.add({
                mode: JunctionMode.AND,
                operator: StringKeywordOperator.is_in,
                value: [...prfIdMap.keys()].join(', ')
            })
        }

        this.__markSelected(prfIdMap)
        this.__parent.fetchData(prfIdMap.size > 0 ? 'listagemDePRF' : '')
    }

    private __markSelected(map: Map<string, boolean> | undefined) {
        if (!map) {
            map = this.collectExplicitValues()
        }

        for (const row of this.scope.dataset) {
            row.selected = map.has(row.ipf)
        }

        this.scope.update()
    }

    @action()
    async __onFiltrationChange(columnFilters: ColumnFilter[]) {
        this.scope.filters = columnFilters
        await this.fetch(this.__filterManager.build(), {
            columnFilters
        })
    }

    @action()
    async __onPaginationChange(pageIndex: number, pageSize: number) {
        if (pageSize !== this.scope.pageSize) {
            pageIndex = 0
        }
        await this.fetch(this.__filterManager.build(), {
            paginationState: { pageIndex, pageSize }
        })
    }

    @action()
    async __onSortingChange(sorting: ColumnSort[]) {
        await this.fetch(this.__filterManager.build(), { sorting })
    }

    private collectExplicitValues() {
        const codigoValMap = new Map<string, boolean>()

        const prop = this.__filterManager.prfCodigo

        for (const filterVal of prop.values()) {
            if (filterVal.value) {
                if (filterVal.operator == StringKeywordOperator.is_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigo && codigoValMap.set(codigo, true))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigoValMap.delete(codigo))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is) {
                    const codigo = lodash.trim(filterVal.value.trim())
                    codigo && codigoValMap.set(codigo, true)
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not) {
                    codigoValMap.delete(filterVal.value)
                    continue
                }
            }
        }
        return codigoValMap
    }
}

function codDscArrayToString(entries: { cod: string; dsc: string }[] | undefined | null) {
    if (!entries || entries.length === 0) {
        return ''
    }
    return entries.map((e) => e.dsc).join('; ')
}

function isoToDate(v: string | undefined | null) {
    if (!v) {
        return undefined
    }
    return DateFnsNs.parseISO(v)
}
