import { ViewFactory } from 'wdc-cube-react'

import TheConsumerMarketScope from '../tcm_scopes'
import TheConsumerMarketView from './tcm_view'

import { HeaderScope } from '../tcm_scopes'
import { HeaderView } from './tcm_header_view'

import { FilterExtraAeView } from './filters/tcm_filter_extra_ae'
import { FilterExtraAeScope } from '../tcm_scopes'

import { FilterExtraCnpjView } from './filters/tcm_filter_extra_cnpj'
import { FilterExtraCnpjScope } from '../tcm_scopes'

export function registerViews(rv = ViewFactory.register) {
    rv(TheConsumerMarketScope, TheConsumerMarketView)
    rv(HeaderScope, HeaderView)
    rv(FilterExtraAeScope, FilterExtraAeView)
    rv(FilterExtraCnpjScope, FilterExtraCnpjView)
}
