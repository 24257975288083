import { DateFns } from 'lang-utils'
import { CubePresenter, FlipIntent, Logger, Presenter } from 'wdc-cube'
import { MainPresenter } from 'src/main/Main.presenter'
import { getTexts, type TCMTexts } from './tcm_texts'
import { TheConsumerMarketKeys } from './tcm_keys'
import { TheConsumerMarketScope, HeaderScope } from './tcm_scopes'
import { TheConsumerMarketService, type SobreOsDados, type EmpresaFilter } from './tcm_service'
import FilterManager from './tcm_filter_manager'

import EmpresasMapPresenter from './tcm_presenter_map'
import EmpresasAbertasFechadasPresenter from './tcm_presenter_empresas_abertas_fechadas'
import EmpresasFaixaCapitalSocialPresenter from './tcm_presenter_empresas_faixa_capital_social'
import EmpresasNoGrupoPresenter from './tcm_presenter_empresas_no_grupo'
import EmpresasFaixaFaturamentoAnualPresenter from './tcm_presenter_empresas_faixa_faturamento_anual'
import EmpresasFaixaFaturamentoConsolidadoGrupoPresenter from './tcm_presenter_empresas_faixa_faturamento_consolidado_grupo'
import EmpresasFaixaFuncionariosPresenter from './tcm_presenter_empresas_faixa_funcionarios'
import EmpresasFaixaFuncionariosGrupoPresenter from './tcm_presenter_empresas_faixa_funcionarios_grupo'
import EmpresasFaixaIdadePresenter from './tcm_presenter_empresas_faixa_idade'
import EmpresaMatrizFiliaisPresenter from './tcm_presenter_empresas_matriz_filiais'
import EmpresasPorSituacaoCadastralPresenter from './tcm_presenter_empresas_situacao_cadastral'
import ForEmpresasTotalPresenter from './tcm_presenter_empresas_total'
import EmpresasTotalAtivasPresenter from './tcm_presenter_empresas_total_ativas'
import EmpresasTotalNovasUltMesPresenter from './tcm_presenter_empresas_total_novas_ultimo_mes'
import EmpresasTableAtivEconomicaPresenter from './tcm_presenter_empresas_table_ativ_economica'
import EmpresasTotalNaturezaJuridicaPresenter from './tcm_presenter_empresas_total_natureza_juridica'

import TcmPresenterListagemTabs from './tcm_presenter_listagem_tabs'

import TcmPresenterForFilterPane from './tcm_presenter_filter_pane'

const LOG = Logger.get('TCM-Presenter')

const fns = DateFns.singleton
const service = TheConsumerMarketService.singleton()

export type CardType =
    | ''
    | 'totalDeEmpresaAtivas'
    | 'totalNovasEmpresasUltimoMes'
    | 'totalDeMatrizesEFiliais'
    | 'totalPorSituacaoCadastral'
    | 'totalPorNaturezaJuridica'
    | 'totalPorAtividadeEconomica'
    | 'relacaoEmpresasAbertasFechadas'
    | 'totalDeEmpresasNoGrupo'
    | 'totalPorFaixaCapitalSocial'
    | 'totalPorFaixaDeFaturamentoAnual'
    | 'totalPorFaixaDeIdade'
    | 'totalPorFaixaDeFuncionarios'
    | 'totalPorFaixaDeFuncionariosGrupo'
    | 'totalPorFaixaDeFaturamentoConsolidadoGrupo'
    | 'listagemDeEmpresas'
    | 'listagemDeQSA'
    | 'listagemDePRF'
    | 'listagemDePES'
    | 'mapa'

export type FetchDataContext = {
    tasks: Promise<void>[]
    source: CardType
    isCard: (name: CardType) => boolean
    filter: EmpresaFilter
}

export class TheConsumerMarketPresenter extends CubePresenter<MainPresenter, TheConsumerMarketScope> {
    // :: Contructor

    constructor(app: MainPresenter) {
        super(app, new TheConsumerMarketScope())
        this.__headerBar = new Presenter(this, this.scope.header)
        this.__texts = getTexts(app.language)
        this.__filterManager = new FilterManager()
        this.__filterManager.bindIsAdditiveModeOn(() => !this.scope.keyboard.ctrlKey)

        this.__empresasMapa = new EmpresasMapPresenter(this)
        this.__empresasAbertasFechadas = new EmpresasAbertasFechadasPresenter(this)
        this.__empresasFaixaCapitalSocial = new EmpresasFaixaCapitalSocialPresenter(this)
        this.__empresasNoGrupo = new EmpresasNoGrupoPresenter(this)
        this.__empresasFaixaFaturamentoAnual = new EmpresasFaixaFaturamentoAnualPresenter(this)
        this.__empresasFaixaFaturamentoConsolidadoGrupo = new EmpresasFaixaFaturamentoConsolidadoGrupoPresenter(this)
        this.__empresasFaixaFuncionarios = new EmpresasFaixaFuncionariosPresenter(this)
        this.__empresasFaixaFuncionariosGrupo = new EmpresasFaixaFuncionariosGrupoPresenter(this)
        this.__empresasFaixaIdade = new EmpresasFaixaIdadePresenter(this)
        this.__empresaMatrizFiliais = new EmpresaMatrizFiliaisPresenter(this)
        this.__empresasPorSituacaoCadastral = new EmpresasPorSituacaoCadastralPresenter(this)
        this.__empresasTotal = new ForEmpresasTotalPresenter(this, this.__empresasMapa)
        this.__empresasTotalAtivas = new EmpresasTotalAtivasPresenter(this)
        this.__empresasTotalNovasUltMes = new EmpresasTotalNovasUltMesPresenter(this)
        this.__empresasTableAtivEconomica = new EmpresasTableAtivEconomicaPresenter(this)
        this.__empresasTotalNaturezaJuridica = new EmpresasTotalNaturezaJuridicaPresenter(this)
        this.__listagemTabs = new TcmPresenterListagemTabs(this)

        this.__filterPane = new TcmPresenterForFilterPane(this)
    }

    // :: State

    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    private readonly __headerBar: Presenter<HeaderScope, TheConsumerMarketPresenter>
    private readonly __empresasMapa: EmpresasMapPresenter
    private readonly __empresasAbertasFechadas: EmpresasAbertasFechadasPresenter
    private readonly __empresasFaixaCapitalSocial: EmpresasFaixaCapitalSocialPresenter
    private readonly __empresasNoGrupo: EmpresasNoGrupoPresenter
    private readonly __empresasFaixaFaturamentoAnual: EmpresasFaixaFaturamentoAnualPresenter
    private readonly __empresasFaixaFaturamentoConsolidadoGrupo: EmpresasFaixaFaturamentoConsolidadoGrupoPresenter
    private readonly __empresasFaixaFuncionarios: EmpresasFaixaFuncionariosPresenter
    private readonly __empresasFaixaFuncionariosGrupo: EmpresasFaixaFuncionariosGrupoPresenter
    private readonly __empresasFaixaIdade: EmpresasFaixaIdadePresenter
    private readonly __empresaMatrizFiliais: EmpresaMatrizFiliaisPresenter
    private readonly __empresasPorSituacaoCadastral: EmpresasPorSituacaoCadastralPresenter
    private readonly __empresasTotal: ForEmpresasTotalPresenter
    private readonly __empresasTotalAtivas: EmpresasTotalAtivasPresenter
    private readonly __empresasTotalNovasUltMes: EmpresasTotalNovasUltMesPresenter
    private readonly __empresasTableAtivEconomica: EmpresasTableAtivEconomicaPresenter
    private readonly __empresasTotalNaturezaJuridica: EmpresasTotalNaturezaJuridicaPresenter
    private readonly __listagemTabs: TcmPresenterListagemTabs

    private readonly __filterPane: TcmPresenterForFilterPane

    private __hasDadosSobreOsDados = false

    // :: Property

    get filterManager() {
        return this.__filterManager
    }

    get filterPane() {
        return this.__filterPane
    }

    get texts() {
        return this.__texts
    }

    // :: Controller lifecycle

    override release() {
        this.app.releaseToolbar(this.__headerBar)
        this.__listagemTabs.release()
        this.__empresasMapa.release()
        this.__empresasAbertasFechadas.release()
        this.__empresasFaixaCapitalSocial.release()
        this.__empresasNoGrupo.release()
        this.__empresasFaixaFaturamentoAnual.release()
        this.__empresasFaixaFaturamentoConsolidadoGrupo.release()
        this.__empresasFaixaFuncionarios.release()
        this.__empresasFaixaFuncionariosGrupo.release()
        this.__empresasFaixaIdade.release()
        this.__empresaMatrizFiliais.release()
        this.__empresasPorSituacaoCadastral.release()
        this.__empresasTotal.release()
        this.__empresasTotalAtivas.release()
        this.__empresasTotalNovasUltMes.release()
        this.__empresasTableAtivEconomica.release()
        this.__empresasTotalNaturezaJuridica.release()
        this.__filterPane.release()
        super.release()
        LOG.debug('Finalized')
    }

    override async applyParameters(intent: FlipIntent, initialization: boolean): Promise<boolean> {
        if (!this.app.scope.theConsumerMarketEnabled) {
            await this.app.flipToDefault()
            return false
        }

        const keys = new TheConsumerMarketKeys(this.app, intent)

        this.app.scope.toolbar = this.__headerBar.scope
        keys.parentSlot(this.scope)

        if (initialization) {
            await this.__initialize(keys)
        } else {
            await this.__update(keys)
        }

        if (keys.last) {
            keys.dialogSlot(null)
        }

        return keys.allow
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    override publishParameters(intent: FlipIntent): void {
        const keys = new TheConsumerMarketKeys(this.app, intent)
        this.__listagemTabs.publishParameters(keys)
    }

    // :: Internals

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private async __initialize(keys: TheConsumerMarketKeys) {
        await this.__filterPane.initialize()

        await this.__empresasTotal.initialize()
        await this.__empresasTotalAtivas.initialize()
        await this.__empresasTotalNovasUltMes.initialize()
        await this.__empresaMatrizFiliais.initialize()
        await this.__empresasPorSituacaoCadastral.initialize()
        await this.__empresasTotalNaturezaJuridica.initialize()
        await this.__empresasTableAtivEconomica.initialize()
        await this.__empresasAbertasFechadas.initialize()
        await this.__empresasNoGrupo.initialize()
        await this.__empresasFaixaCapitalSocial.initialize()
        await this.__empresasFaixaIdade.initialize()
        await this.__empresasFaixaFuncionarios.initialize()
        await this.__empresasFaixaFuncionariosGrupo.initialize()
        await this.__empresasFaixaFaturamentoAnual.initialize()
        await this.__empresasFaixaFaturamentoConsolidadoGrupo.initialize()
        await this.__empresasMapa.initialize()
        await this.__listagemTabs.initialize(keys)

        this.fetchData('').catch(LOG.caught)
    }

    private async __update(keys: TheConsumerMarketKeys) {
        await this.__listagemTabs.updateFromIntent(keys)
    }

    getFilter() {
        this.__filterPane.publish()
        return this.__filterManager.build()
    }

    // prettier-ignore
    async fetchData(source: CardType) {
        const mgr = this.__filterManager

        if(!this.__hasDadosSobreOsDados) {
            await service.fetchSobreOsDados().then(this.__updateSobreOsDados.bind(this))
            this.__hasDadosSobreOsDados = true
        }

        this.__filterPane.publish()

        const isCard = (name: CardType) => name !== source
        const tasks: Promise<void>[] = []
        const filter = mgr.build()
        const fetchDataCtx: FetchDataContext = { tasks, source, isCard, filter}

        isCard('totalDeEmpresaAtivas') && tasks.push(this.__empresasTotalAtivas.fetch(filter))
        isCard('totalNovasEmpresasUltimoMes') && tasks.push(this.__empresasTotalNovasUltMes.fetch(filter))
        isCard('totalDeMatrizesEFiliais') && tasks.push(this.__empresaMatrizFiliais.fetch(filter))
        isCard('totalPorSituacaoCadastral') && tasks.push(this.__empresasPorSituacaoCadastral.fetch(filter))
        isCard('totalPorNaturezaJuridica') && tasks.push(this.__empresasTotalNaturezaJuridica.fetch(filter))
        isCard('totalPorAtividadeEconomica') && tasks.push(this.__empresasTableAtivEconomica.fetch(filter))
        isCard('relacaoEmpresasAbertasFechadas') && tasks.push(this.__empresasAbertasFechadas.fetch(filter))
        isCard('totalDeEmpresasNoGrupo') && tasks.push(this.__empresasNoGrupo.fetch(filter))
        isCard('totalPorFaixaCapitalSocial') && tasks.push(this.__empresasFaixaCapitalSocial.fetch(filter))
        isCard('totalPorFaixaDeFaturamentoAnual') && tasks.push(this.__empresasFaixaFaturamentoAnual.fetch(filter))
        isCard('totalPorFaixaDeIdade') && tasks.push(this.__empresasFaixaIdade.fetch(filter))
        isCard('totalPorFaixaDeFuncionarios') && tasks.push(this.__empresasFaixaFuncionarios.fetch(filter))
        isCard('totalPorFaixaDeFuncionariosGrupo') && tasks.push(this.__empresasFaixaFuncionariosGrupo.fetch(filter))
        isCard('totalPorFaixaDeFaturamentoConsolidadoGrupo') && tasks.push(this.__empresasFaixaFaturamentoConsolidadoGrupo.fetch(filter))

        await this.__listagemTabs.fetchData(fetchDataCtx)

        tasks.push(this.__empresasTotal.fetch(filter))
        tasks.push(this.__empresasMapa.fetch(filter))

        return Promise.all(tasks)
    }

    private async __updateSobreOsDados(sobreOsDados: SobreOsDados) {
        const dataDeCorte = fns().parseISO(sobreOsDados.dataCorte)
        this.scope.header.dataAtualizacao = this.app.formatDate(fns().parseISO(sobreOsDados.atualizadoEm))
        this.scope.header.dataDeCorte = this.app.formatDate(dataDeCorte)
        this.__filterManager.setDataDeCorte(dataDeCorte)
    }
}
