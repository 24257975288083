import { Logger, Presenter, action } from 'wdc-cube'
import { TheConsumerMarketKeys } from './tcm_keys'
import { TheConsumerMarketPresenter, type FetchDataContext } from './tcm_presenter'
import { ListagemTabsScope, type ListagemTabsOption } from './tcm_scopes'
import EmpresasListagemPresenter from './tcm_presenter_listagem_for_empresas'
import QSAListagemPresenter from './tcm_presenter_listagem_for_qsa'
import PRFListagemPresenter from './tcm_presenter_listagem_for_prf'
import PESListagemPresenter from './tcm_presenter_listagem_for_pes'

const LOG = Logger.get('TcmPresenterListagemTabs')

export default class TcmPresenterListagemTabs extends Presenter<ListagemTabsScope, TheConsumerMarketPresenter> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.listagemTabs)
        this.__parent = owner

        // Listagens
        this.__empresasListagem = new EmpresasListagemPresenter(owner)
        this.__qsaListagem = new QSAListagemPresenter(owner)
        this.__prfListagem = new PRFListagemPresenter(owner)
        this.__pesListagem = new PESListagemPresenter(owner)
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter

    private readonly __empresasListagem: EmpresasListagemPresenter
    private readonly __qsaListagem: QSAListagemPresenter
    private readonly __prfListagem: PRFListagemPresenter
    private readonly __pesListagem: PESListagemPresenter

    // :: Public API

    override release(): void {
        this.__empresasListagem.release()
        this.__qsaListagem.release()
        this.__prfListagem.release()
        this.__pesListagem.release()
        super.release()
        LOG.debug('release')
    }

    async initialize(keys: TheConsumerMarketKeys) {
        this.scope.active = toListagemTabsOption(keys.selectedListingTab)
        this.scope.onSelect = this.handleTabSelected.bind(this)

        await this.__empresasListagem.initialize()
        await this.__qsaListagem.initialize()
        await this.__prfListagem.initialize()
        await this.__pesListagem.initialize()
        LOG.debug('Initialized')
    }

    async updateFromIntent(keys: TheConsumerMarketKeys) {
        const newActiveTab = toListagemTabsOption(keys.selectedListingTab) 
        if (this.scope.active !== newActiveTab) {
            await this.handleTabSelected(newActiveTab)
        }
    }

    publishParameters(keys: TheConsumerMarketKeys): void {
        keys.selectedListingTab = this.scope.active
    }

    clear() {
        this.__empresasListagem.clear()
        this.__qsaListagem.clear()
        this.__prfListagem.clear()
        this.__pesListagem.clear()
    }

    async fetchData(ctx: FetchDataContext) {
        const { isCard, tasks, filter } = ctx
        if (this.scope.active === 'pj' && isCard('listagemDeEmpresas')) {
            tasks.push(this.__empresasListagem.fetch(filter))
        }

        if (this.scope.active === 'qsa' && isCard('listagemDeQSA')) {
            tasks.push(this.__qsaListagem.fetch(filter))
        }

        if (this.scope.active === 'prf' && isCard('listagemDePRF')) {
            tasks.push(this.__prfListagem.fetch(filter))
        }

        if (this.scope.active === 'pes' && isCard('listagemDePES')) {
            tasks.push(this.__pesListagem.fetch(filter))
        }
    }

    @action()
    private async handleTabSelected(tab: ListagemTabsOption) {
        this.scope.active = tab
        this.owner.app.updateHistory()

        if (tab === 'pj') {
            const filter = this.__parent.getFilter()
            await this.__empresasListagem.fetch(filter)
            return
        }

        if (tab === 'qsa') {
            const filter = this.__parent.getFilter()
            await this.__qsaListagem.fetch(filter)
            return
        }

        if (tab === 'prf') {
            const filter = this.__parent.getFilter()
            await this.__prfListagem.fetch(filter)
            return
        }

        if (tab === 'pes') {
            const filter = this.__parent.getFilter()
            await this.__pesListagem.fetch(filter)
            return
        }
    }
}

function toListagemTabsOption(v: string | undefined | null): ListagemTabsOption {
    if (v === 'qsa') return 'qsa'
    if (v === 'prf') return 'prf'
    if (v === 'pes') return 'pes'
    return 'pj'
}
