import React from 'react'
import SvgIcon from '@mui/material/SvgIcon'
import { ApplicationLogoProps } from './types'

const FetraLogo = ({ place, ...props }: ApplicationLogoProps) => {
    return (
        <SvgIcon viewBox="0 0 1920 706" fill="none" {...props}>
            <path
                fill={place === 'sign-in' ? '#445297' : '#FFFFFF'}
                d="M291.3,121.4C163.8,121.4,60,225.2,60,352.7C60,480.3,163.8,584,291.3,584s231.3-103.8,231.3-231.3
	C522.6,225.2,418.8,121.4,291.3,121.4z M291.3,136.1c21.4,0,42.1,3.2,61.6,9c-48.5-2.8-95.9,10.1-133.9,37.8
	c-47.2,34.3-72.7,85.9-72,145.3c-1.3,7.8-2,15.8-2,24c0,63.7,41.7,117.8,99.4,137.1c-33.7-2.2-66.4-14.2-94.7-35.8
	c-46.2-35.2-72.3-90-69.9-146.9C100.9,209.3,187.7,136.1,291.3,136.1z M421.7,352.2c0,71.7-58.8,129.9-131.1,129.9
	s-131.1-58.3-131.1-129.9c0-71.6,58.8-129.9,131.1-129.9S421.7,280.6,421.7,352.2z M76.2,377.6c12.4,34.1,34.5,64.7,64.5,87.6
	c34,25.9,73.8,39.2,114.5,39.2c19.7,0,39.6-3.1,59.1-9.5c69.2-11.2,122.1-70.9,122.1-142.7c0-5.6-0.4-11.2-1-16.7
	c13.9,32,16.9,67.4,8,102.4c-15.2,59.3-60.8,107.6-122.1,129.3c-9.8,1.4-19.8,2.1-30,2.1C180.3,569.3,88.5,485.4,76.2,377.6z
	 M390.2,545.3c33.1-26.8,56.9-62.8,67.4-103.8c14.7-57.5-0.1-116.1-40.5-161.1c-25.2-43.5-72.4-72.9-126.5-72.9
	c-49.5,0-93.3,24.7-119.7,62.2c10.5-29.8,29.7-55.5,56.6-75c50-36.3,117.4-45.3,180.7-24.2c59.8,38.6,99.6,105.8,99.6,182.1
	C507.9,436.5,460,509.3,390.2,545.3z M623.9,292.8v108.7h143.1v25.3H623.9v117.4h-29.3V267.5h189.8v25.3H623.9z M1040.7,519v25.3
	H845V267.5h189.8v25.3H874.2v98.5h143.1v24.9H874.2V519H1040.7z M1163.3,292.8H1066v-25.3h223.8v25.3h-97.3v251.5h-29.3V292.8z
	 M1439.3,431.6c27.4,0,48.4-6.1,62.9-18.2c14.5-12.1,21.7-29.1,21.7-51c0-22.4-7.2-39.6-21.7-51.6c-14.5-12-35.5-18-62.9-18h-73.5
	v138.8H1439.3z M1525.5,544.3l-63.3-89c-7.1,0.8-14.5,1.2-22.1,1.2h-74.3v87.8h-29.3V267.5h103.6c35.3,0,63,8.4,83,25.3
	c20,16.9,30.1,40.1,30.1,69.6c0,21.6-5.5,39.9-16.4,54.8c-10.9,14.9-26.6,25.6-46.9,32.2l67.6,94.9H1525.5z M1785.3,446.6
	L1718.8,298l-66.4,148.7H1785.3z M1795.9,470.4h-154.2l-33.2,73.9h-30.4l126.5-276.8h28.9L1860,544.3h-30.8L1795.9,470.4z
	 M658.2,197.2h8.2V228c-3.8,3.3-8.2,5.9-13.4,7.6c-5.2,1.8-10.6,2.7-16.3,2.7c-8.1,0-15.4-1.8-21.8-5.3c-6.5-3.5-11.6-8.5-15.3-14.7
	c-3.7-6.3-5.5-13.3-5.5-21.1c0-7.8,1.8-14.8,5.5-21.1c3.7-6.3,8.8-11.2,15.3-14.7c6.5-3.5,13.8-5.3,22-5.3c6.2,0,11.8,1,17,2.9
	c5.2,2,9.5,4.9,13.1,8.7l-5.3,5.4c-6.4-6.3-14.5-9.5-24.4-9.5c-6.5,0-12.5,1.4-17.7,4.3c-5.3,2.9-9.4,6.9-12.4,12
	c-3,5.1-4.5,10.8-4.5,17.2c0,6.3,1.5,12,4.5,17.1c3,5.1,7.1,9.1,12.4,12c5.2,2.9,11.1,4.4,17.7,4.4c8.3,0,15.4-2.1,21.1-6.2V197.2z
	 M720.6,204.7c8,0,14.1-1.8,18.4-5.3c4.2-3.5,6.4-8.5,6.4-14.9c0-6.5-2.1-11.6-6.4-15.1c-4.2-3.5-10.4-5.3-18.4-5.3h-21.5v40.6
	H720.6z M745.8,237.6l-18.5-26c-2.1,0.2-4.2,0.3-6.5,0.3h-21.7v25.7h-8.6v-80.9h30.3c10.3,0,18.4,2.5,24.3,7.4
	c5.9,4.9,8.8,11.7,8.8,20.3c0,6.3-1.6,11.7-4.8,16c-3.2,4.4-7.8,7.5-13.7,9.4l19.8,27.7H745.8z M806.7,238.3c-10.5,0-18.6-3-24.5-9
	c-5.9-6-8.8-14.8-8.8-26.2v-46.3h8.6v46c0,9.4,2.1,16.4,6.4,21c4.2,4.6,10.4,6.9,18.4,6.9c8.1,0,14.3-2.3,18.5-6.9
	c4.2-4.6,6.4-11.6,6.4-21v-46h8.3v46.3c0,11.5-2.9,20.2-8.7,26.2C825.3,235.3,817.2,238.3,806.7,238.3 M895.7,204.6
	c8,0,14.1-1.8,18.4-5.3c4.2-3.5,6.4-8.5,6.4-14.8c0-6.5-2.1-11.6-6.4-15.1c-4.2-3.5-10.4-5.3-18.4-5.3h-21.5v40.4H895.7z
	 M895.9,156.7c10.3,0,18.4,2.5,24.3,7.4c5.9,4.9,8.8,11.7,8.8,20.3c0,8.6-2.9,15.4-8.8,20.3c-5.9,4.9-13.9,7.3-24.3,7.3h-21.7v25.5
	h-8.6v-80.9H895.9z M984,230.7c6.4,0,12.2-1.4,17.3-4.3c5.2-2.9,9.2-6.9,12.1-12c2.9-5.1,4.4-10.8,4.4-17.2c0-6.3-1.5-12-4.4-17.2
	c-2.9-5.1-7-9.1-12.1-12c-5.2-2.9-10.9-4.3-17.3-4.3c-6.4,0-12.2,1.4-17.4,4.3c-5.2,2.9-9.3,6.9-12.2,12c-3,5.1-4.4,10.8-4.4,17.2
	c0,6.3,1.5,12,4.4,17.2c3,5.1,7,9.1,12.2,12C971.8,229.2,977.6,230.7,984,230.7 M984,238.3c-8,0-15.3-1.8-21.8-5.4
	c-6.5-3.6-11.6-8.5-15.3-14.7c-3.7-6.2-5.5-13.3-5.5-21c0-7.8,1.8-14.8,5.5-21c3.7-6.2,8.7-11.2,15.3-14.7
	c6.5-3.6,13.8-5.4,21.8-5.4c8,0,15.2,1.8,21.7,5.3c6.4,3.5,11.5,8.5,15.2,14.7c3.7,6.3,5.5,13.3,5.5,21.1c0,7.8-1.8,14.8-5.5,21.1
	c-3.7,6.3-8.8,11.2-15.2,14.7C999.2,236.5,992,238.3,984,238.3z"
            />
        </SvgIcon>
    )
}

export default FetraLogo
