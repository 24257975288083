import lodash from 'lodash'
import * as DateFnsNs from 'date-fns'
import { Logger, Presenter, action } from 'wdc-cube'
import * as lang from 'src/utils/lang'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { TableQSAScope } from './tcm_scopes'
import {
    TheConsumerMarketService,
    StringKeywordOperator,
    JunctionMode,
    type EmpresaFilter,
    type ColumnSort,
    type ColumnFilter
} from './tcm_service'

type PaginationState = {
    pageIndex: number
    pageSize: number
}

const LOG = Logger.get('TcmPresenterForQSAListagem')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForQSAListagem extends Presenter<TableQSAScope, TheConsumerMarketPresenter> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.listagemTabs.qsaListagem)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.update = this.update
        this.scope.caption = this.__texts.qsaListagem
        this.scope.onSelectionChange = this.__onSocioRowClicked.bind(this)
        this.scope.onFiltrationChange = this.__onFiltrationChange.bind(this)
        this.scope.onPaginationChange = this.__onPaginationChange.bind(this)
        this.scope.onSortingChange = this.__onSortingChange.bind(this)
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.scope.pageIndex = 0
        this.scope.pageSize = 100
        this.owner.filterPane.socioNome.clear()
        this.__filterManager.socioCodigo.clear()
        this.__filterManager.socioNome.clear()
    }

    async fetch(
        filter: EmpresaFilter,
        cfg?: {
            paginationState?: PaginationState
            sorting?: ColumnSort[]
            columnFilters?: ColumnFilter[]
        }
    ) {
        let { paginationState, sorting, columnFilters } = cfg ?? {}
        this.scope.loaded = false
        try {
            if (!columnFilters) {
                columnFilters = this.scope.filters
            }

            if (!paginationState) {
                paginationState = {
                    pageIndex: 0,
                    pageSize: this.scope.pageSize
                }
            }

            if (!sorting) {
                sorting = this.scope.sorting
            }

            let search_after: unknown
            if (paginationState.pageIndex > 0) {
                const lastRow = this.scope.dataset[this.scope.dataset.length - 1]
                if (lastRow) {
                    const sortValues: unknown[] = []
                    for (const sortItem of sorting) {
                        sortValues.push((lastRow as Record<string, unknown>)[sortItem.id])
                    }
                    sortValues.push(lastRow.socio_id)
                    search_after = sortValues
                }
            }

            const resp = await service.fetch({
                listagem_qsa: {
                    sorting,
                    columnFilters,
                    search_after,
                    pageSize: paginationState.pageSize
                },
                filter: filter
            })

            this.scope.pageIndex = paginationState.pageIndex
            this.scope.pageSize = paginationState.pageSize
            this.scope.sorting = sorting

            const dataset = resp.listagem_qsa ?? []
            for (let i = 0; i < dataset.length; i++) {
                const row = dataset[i]
                if (lodash.isString(row.entrada_sociedade_dta)) {
                    row.entrada_sociedade_dta = DateFnsNs.parseISO(row.entrada_sociedade_dta)
                }
            }
            LOG.debug(`qsa_dataset.length=${dataset.length}`)

            this.scope.rowCount = resp.listagem_qsa_agg ? resp.listagem_qsa_agg['qsa-count'].value | 0 : 0
            this.scope.dataset = dataset
            this.scope.datasetVersion++

            this.__markSelected(undefined)
        } finally {
            this.scope.loaded = true
        }
    }

    private async __onSocioRowClicked(selectionMap: Map<string, boolean>) {
        const socioIdMap = new Map<string, boolean>()

        this.scope.dataset.forEach((row) => {
            if (selectionMap.get(row.key) === true) {
                socioIdMap.set(row.socio_id, true)
            }
        })

        const prop = this.__filterManager.socioCodigo
        prop.clear()
        if (socioIdMap.size > 0) {
            prop.add({
                mode: JunctionMode.AND,
                operator: StringKeywordOperator.is_in,
                value: [...socioIdMap.keys()].join(', ')
            })
        }

        this.__markSelected(socioIdMap)
        this.__parent.fetchData(socioIdMap.size > 0 ? 'listagemDeQSA' : '')
    }

    private __markSelected(map: Map<string, boolean> | undefined) {
        if (!map) {
            map = this.collectExplicitValues()
        }

        for (const row of this.scope.dataset) {
            row.selected = map.has(row.socio_id)
        }

        this.scope.update()
    }

    @action()
    async __onFiltrationChange(columnFilters: ColumnFilter[]) {
        this.scope.filters = columnFilters
        await this.fetch(this.__filterManager.build(), {
            columnFilters
        })
    }

    @action()
    async __onPaginationChange(pageIndex: number, pageSize: number) {
        if (pageSize !== this.scope.pageSize) {
            pageIndex = 0
        }
        await this.fetch(this.__filterManager.build(), {
            paginationState: { pageIndex, pageSize }
        })
    }

    @action()
    async __onSortingChange(sorting: ColumnSort[]) {
        await this.fetch(this.__filterManager.build(), { sorting })
    }

    private collectExplicitValues() {
        const codigoValMap = new Map<string, boolean>()

        const prop = this.__filterManager.socioCodigo

        for (const filterVal of prop.values()) {
            if (filterVal.value) {
                if (filterVal.operator == StringKeywordOperator.is_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigo && codigoValMap.set(codigo, true))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigoValMap.delete(codigo))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is) {
                    const codigo = lodash.trim(filterVal.value.trim())
                    codigo && codigoValMap.set(codigo, true)
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not) {
                    codigoValMap.delete(filterVal.value)
                    continue
                }
            }
        }
        return codigoValMap
    }
}
