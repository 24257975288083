import React from 'react'
import { makeStyles } from 'tss-react/mui'
import { SearchOption } from '../sb_types'

const useStyles = makeStyles()(() => ({
    categoriaLabel: {
        textAlign: 'right',
        marginRight: 5,
        whiteSpace: 'nowrap'
    }
}))

export type ItemOptionProps = React.HTMLAttributes<HTMLLIElement> & {
    option: SearchOption
}

export default function ItemOption({ option, ...props }: ItemOptionProps) {
    const classes = useStyles().classes

    if (option.label) {
        return (
            <li {...props} key={option.id}>
                <span className={classes.categoriaLabel}>{option.label}:</span>
                <b>{option.text}</b>
            </li>
        )
    }

    return (
        <li {...props} key={option.id}>
            {option.text}
        </li>
    )
}
