type PrimaryColors = {
    main: string
    light: string
    dark: string
    contrastText: string
}

const primaryColors: PrimaryColors = {
    main: '#1976d2',
    light: '#42a5f5',
    dark: '#1565c0',
    contrastText: '#fff'
}

switch (location.hostname) {
    case 'fetra.lifeshub.com.br':
        primaryColors.main = '#383838'
        primaryColors.dark = '#222020'
        break
    case 'oliscorp.lifeshub.com.br':
        primaryColors.main = '#383838'
        primaryColors.dark = '#222020'
        break
}

const Colors = { primary: primaryColors }

export default Colors
