import { Observable, ObservableArray, observe, Scope } from 'wdc-cube'
import * as L from 'leaflet'
import {
    type CompanyRow,
    type ProfessionalRow,
    type ProfessionalSortModel,
    type CompanySortModel,
    type SortDirection,
    type GeoLocationMode
} from './ta_types'

import { SearchBoxScope, type TextualSearchRequest } from 'src/components/searchbox'

export { type GeoLocationMode }
export { type CompanyRow, type ProfessionalRow, type TextualSearchRequest, type SortDirection, SearchBoxScope }

@Observable
export class HeaderScope extends Scope {
    @observe() onlyActives = false

    readonly search = new SearchBoxScope()

    onOnlyActivesChange = Scope.ASYNC_ACTION_BOOLEAN
}

@Observable
export class ScoreCardScope extends Scope {
    @observe() label = ''
    @observe() value = ''
    @observe() unit = ''
}

@Observable
export class ProfessionalCardScope extends Scope {
    @observe() expanded = false
    @observe() rows = [] as ProfessionalRow[]
    @observe() total = 0
    @observe() page = 0
    @observe() pageSize = 100
    @observe() downloadEnabled = false
    @observe() working = false

    readonly sortBy: ProfessionalSortModel[] = []

    onExpandClick = Scope.ASYNC_ACTION
    onShrunkClick = Scope.ASYNC_ACTION
    onDownloadClick = Scope.ASYNC_ACTION
    onPageChange = Scope.ASYNC_ACTION_NUMBER
    onPageSizeChange = Scope.ASYNC_ACTION_NUMBER
    onRowClick = Scope.ASYNC_ACTION_ONE<ProfessionalRow>()
    onSortModelChange = Scope.ASYNC_ACTION_ONE<ProfessionalSortModel[]>()
}

@Observable
export class CompanyCardScope extends Scope {
    @observe() expanded = false
    @observe() rows = [] as CompanyRow[]
    @observe() total = 0
    @observe() page = 0
    @observe() pageSize = 100
    @observe() downloadEnabled = false
    @observe() working = false

    readonly sortBy: CompanySortModel[] = []

    onExpandClick = Scope.ASYNC_ACTION
    onShrunkClick = Scope.ASYNC_ACTION
    onDownloadClick = Scope.ASYNC_ACTION
    onPageChange = Scope.ASYNC_ACTION_NUMBER
    onPageSizeChange = Scope.ASYNC_ACTION_NUMBER
    onRowClick = Scope.ASYNC_ACTION_ONE<CompanyRow>()
    onSortModelChange = Scope.ASYNC_ACTION_ONE<CompanySortModel[]>()
}

@Observable
export class SliderScope extends Scope {
    @observe() min = 10
    @observe() max = 1000
    @observe() step = 10
    @observe() value = 50
    @observe() unit: 'km' | 'm' = 'm'
    @observe() visible = false

    onValueChanged = Scope.ASYNC_ACTION_NUMBER
}

@Observable
export class MapCardScope extends Scope {
    @observe() expanded = false
    @observe() showClearButton = false
    @observe() mode: GeoLocationMode = 'route'

    readonly slider = new SliderScope()

    onExpandClick = Scope.ASYNC_ACTION
    onShrunkClick = Scope.ASYNC_ACTION
    onMapChanged = Scope.ASYNC_ACTION_ONE<L.Map | null>()
    onClearFilters = Scope.ASYNC_ACTION
    onSetAreaMode = Scope.ASYNC_ACTION
    onSetRouteMode = Scope.ASYNC_ACTION
    onSetCircleMode = Scope.ASYNC_ACTION
}

@Observable
export class FilterEntryScope extends Scope {
    id = ''
    @observe() label = ''
    value: unknown

    onRemoveClick = Scope.ASYNC_ACTION
}

@Observable
export class OverlayLivreScope extends Scope {
    texts = new ObservableArray<FilterEntryScope>(this)

    getAllFilters() {
        return [this.texts]
    }

    hasContent() {
        return hasFilters(...this.getAllFilters())
    }
}

@Observable
export class OverlayEmpresaScope extends Scope {
    cnpjs = new ObservableArray<FilterEntryScope>(this)
    empresas = new ObservableArray<FilterEntryScope>(this)
    cnaes = new ObservableArray<FilterEntryScope>(this)
    empNatureza = new ObservableArray<FilterEntryScope>(this)
    empEnderecos = new ObservableArray<FilterEntryScope>(this)
    empCeps = new ObservableArray<FilterEntryScope>(this)
    empLogradouros = new ObservableArray<FilterEntryScope>(this)
    empBairros = new ObservableArray<FilterEntryScope>(this)
    empMunicipios = new ObservableArray<FilterEntryScope>(this)

    getAllFilters() {
        return [
            this.cnpjs,
            this.empresas,
            this.cnaes,
            this.empNatureza,
            this.empEnderecos,
            this.empCeps,
            this.empLogradouros,
            this.empBairros,
            this.empMunicipios
        ]
    }

    hasContent() {
        return hasFilters(...this.getAllFilters())
    }
}

@Observable
export class OverlayPessoaScope extends Scope {
    pessoas = new ObservableArray<FilterEntryScope>(this)
    pesEnderecos = new ObservableArray<FilterEntryScope>(this)
    pesCeps = new ObservableArray<FilterEntryScope>(this)
    pesLogradouros = new ObservableArray<FilterEntryScope>(this)
    pesBairros = new ObservableArray<FilterEntryScope>(this)
    pesMunicipios = new ObservableArray<FilterEntryScope>(this)
    pesCbos = new ObservableArray<FilterEntryScope>(this)

    getAllFilters() {
        return [
            this.pessoas,
            this.pesEnderecos,
            this.pesCeps,
            this.pesLogradouros,
            this.pesBairros,
            this.pesMunicipios,
            this.pesCbos
        ]
    }

    hasContent() {
        return hasFilters(...this.getAllFilters())
    }
}

@Observable
export class OverlaySocioScope extends Scope {
    socios = new ObservableArray<FilterEntryScope>(this)

    getAllFilters() {
        return [this.socios]
    }

    readonly hasContent = () => hasFilters(...this.getAllFilters())
}

@Observable
export class OverlayCnesScope extends Scope {
    cnes = new ObservableArray<FilterEntryScope>(this)
    cnesTipo = new ObservableArray<FilterEntryScope>(this)
    cnesEnderecos = new ObservableArray<FilterEntryScope>(this)
    cnesCeps = new ObservableArray<FilterEntryScope>(this)
    cnesLogradouros = new ObservableArray<FilterEntryScope>(this)
    cnesBairros = new ObservableArray<FilterEntryScope>(this)
    cnesMunicipios = new ObservableArray<FilterEntryScope>(this)

    getAllFilters() {
        return [
            this.cnes,
            this.cnesTipo,
            this.cnesEnderecos,
            this.cnesCeps,
            this.cnesLogradouros,
            this.cnesBairros,
            this.cnesMunicipios
        ]
    }

    hasContent() {
        return hasFilters(...this.getAllFilters())
    }
}

@Observable
export class OverlayPessoaAndSocioScope extends Scope {
    cpfs = new ObservableArray<FilterEntryScope>(this)
    cids = new ObservableArray<FilterEntryScope>(this)
    atos = new ObservableArray<FilterEntryScope>(this)
    atoCbos = new ObservableArray<FilterEntryScope>(this)
    espMeds = new ObservableArray<FilterEntryScope>(this)
    crms = new ObservableArray<FilterEntryScope>(this)
    crmUfs = new ObservableArray<FilterEntryScope>(this)
    crmDataInscricao = new ObservableArray<FilterEntryScope>(this)

    getAllFilters() {
        return [this.cpfs, this.cids, this.atos, this.atoCbos, this.espMeds, this.crms, this.crmUfs, this.crmDataInscricao]
    }

    hasContent() {
        return hasFilters(...this.getAllFilters())
    }
}

@Observable
export class OverlayOperadoraScope extends Scope {
    empOps = new ObservableArray<FilterEntryScope>(this)
    prfOps = new ObservableArray<FilterEntryScope>(this)

    getAllFilters() {
        return [this.empOps, this.prfOps]
    }

    hasContent() {
        return hasFilters(...this.getAllFilters())
    }
}

function hasFilters(...filters: ObservableArray<FilterEntryScope>[]) {
    for (const filter of filters) {
        if (filter.length > 0) {
            return true
        }
    }
    return false
}

@Observable
export class OverlayScope extends Scope {
    @observe() expanded = false
    @observe() downloading = false
    @observe() downloadEnabled = false

    livre = new OverlayLivreScope()
    empresa = new OverlayEmpresaScope()
    pessoa = new OverlayPessoaScope()
    cnes = new OverlayCnesScope()
    socios = new OverlaySocioScope()
    pessoaAndSocio = new OverlayPessoaAndSocioScope()
    operadoras = new OverlayOperadoraScope()

    onFilterToggleClick = Scope.ASYNC_ACTION
    onDownloadClick = Scope.ASYNC_ACTION
}

@Observable
export class TheActingScope extends Scope {
    @observe() selectedCard: 'none' | 'professional' | 'company' | 'map' = 'none'

    readonly overlay = new OverlayScope()

    readonly profissionalScoreCard = new ScoreCardScope()
    readonly companyScoreCard = new ScoreCardScope()
    readonly revenueScoreCard = new ScoreCardScope()
    readonly healthCoScoreCard = new ScoreCardScope()

    readonly professionalCard = new ProfessionalCardScope()
    readonly companyCard = new CompanyCardScope()
    readonly mapCard = new MapCardScope()

    readonly keyboard = {
        ctrlKey: false
    }
}

let DEFAULT_CENTER: L.LatLngLiteral = { lat: -15.7754462, lng: -47.7970891 }

export const ScopeDefaults = new (class {
    getDefaultCenter(): L.LatLngLiteral {
        return { ...DEFAULT_CENTER }
    }

    setDefaultCenter(value: L.LatLngLiteral) {
        DEFAULT_CENTER = value
    }
})()
