import React from 'react'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { FilterExtraCnpjScope } from '../../tcm_scopes'
import { Checkbox, FormControlLabel } from '@mui/material'

type FilterExtraCnpjViewProps = {
    className?: string
    scope: FilterExtraCnpjScope
}

class FilterExtraCnpjViewClass implements FCClassContext<FilterExtraCnpjViewProps> {
    scope!: FilterExtraCnpjScope

    onSyncState({ scope }: FilterExtraCnpjViewProps) {
        this.scope = scope
    }

    render({ className, scope }: FilterExtraCnpjViewProps): JSX.Element {
        return (
            <FormControlLabel
                className={className}
                label={scope.cloning ? 'Modo clonagem' : ' Modo normal'}
                control={
                    <Checkbox
                        checked={true}
                        onChange={this.emitOnModeChanged}
                    />
                }
            />
        )
    }

    emitOnModeChanged = () => {
        this.scope.onModeChange(!this.scope.cloning)
    }
}

export const FilterExtraCnpjView = classToFComponent(FilterExtraCnpjViewClass, React)
export default FilterExtraCnpjView
