import lodash from 'lodash'
import { DateFns } from 'lang-utils'
import {
    SituacaoCadastral,
    MatrizOrFilial,
    NumberOperator,
    type StringFilter,
    type StringKeywordFilter,
    type EmpresaCodeFilter,
    type NumberFilter,
    type DateRange,
    type EmpresaFilter,
    type GeoLocation,
    type LocalizacaoFilter,
    type TipoAtividadeEconomicaTextFilter
} from './tcm_service'

export { type StringFilter, type StringKeywordFilter, type NumberFilter, type TipoAtividadeEconomicaTextFilter }

const fns = DateFns.singleton

export default class FilterManager {
    private __dataDeCorte = new Date()
    readonly serMatrizOrFilial = new ValueProperty<MatrizOrFilial>(this)

    readonly situacaoCadastral = new ValueProperty<SituacaoCadastral>(this)

    readonly localizacaoCircles = new ObjectProperty<GeoLocation[]>(this, true)
    readonly localizacaoRoutes = new ObjectProperty<GeoLocation[]>(this, true)
    readonly localizacaoPolygons = new ObjectProperty<GeoLocation[]>(this, true)

    readonly novasUltimoMes = new NovasUltimoMesProperty(this, () => this.__dataDeCorte)

    readonly empresaRazaoSocial = new ValueProperty<StringFilter>(this)
    readonly empresaNomeFantasia = new ValueProperty<StringFilter>(this)
    readonly empresaCodigo = new ValueProperty<EmpresaCodeFilter>(this)

    readonly socioCodigo = new ValueProperty<StringKeywordFilter>(this)
    readonly socioNome = new ValueProperty<StringFilter>(this)

    readonly prfCodigo = new ValueProperty<StringKeywordFilter>(this)
    readonly prfNome = new ValueProperty<StringFilter>(this)

    readonly pesCodigo = new ValueProperty<StringKeywordFilter>(this)
    readonly pesNome = new ValueProperty<StringFilter>(this)

    readonly naturezaJuridicaCodes = new ValueProperty<StringKeywordFilter>(this)
    readonly naturezaJuridicaTexts = new ValueProperty<StringFilter>(this)
    readonly atividadeEconomicaCodes = new ValueProperty<StringKeywordFilter>(this)
    readonly atividadeEconomicaTexts = new ValueProperty<StringFilter>(this)
    readonly tipoAtividadeEconomicaTexts = new ValueProperty<TipoAtividadeEconomicaTextFilter>(this)
    readonly empresaCnes = new ValueProperty<StringKeywordFilter>(this)
    readonly scoreRiscos = new ValueProperty<StringKeywordFilter>(this)

    readonly empresaGrupoCodes = new ValueProperty<StringKeywordFilter>(this)

    readonly enderecoCodes = new ValueProperty<StringKeywordFilter>(this)
    readonly enderecoTexts = new ValueProperty<StringFilter>(this)

    readonly enderecoRuaCodes = new ValueProperty<StringKeywordFilter>(this)
    readonly enderecoRuaTexts = new ValueProperty<StringFilter>(this)

    readonly enderecoBairroCodes = new ValueProperty<StringKeywordFilter>(this)
    readonly enderecoBairroTexts = new ValueProperty<StringFilter>(this)

    readonly enderecoCidadeCodes = new ValueProperty<StringKeywordFilter>(this)
    readonly enderecoCidadeTexts = new ValueProperty<StringFilter>(this)

    readonly enderecoUfs = new ValueProperty<StringKeywordFilter>(this)
    readonly enderecoUfTexts = new ValueProperty<StringFilter>(this)

    readonly enderecoCeps = new ValueProperty<StringKeywordFilter>(this)
    readonly enderecoCepTexts = new ValueProperty<StringFilter>(this)

    readonly enderecoRegiaoMetropolitanaCodes = new ValueProperty<StringKeywordFilter>(this)
    readonly enderecoRegiaoMetropolitanaTexts = new ValueProperty<StringFilter>(this)

    readonly inicioAtividade = new ObjectProperty<DateRange>(this)
    readonly totalEmpresasNoGrupo = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly idadeEmpresaEmAnos = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly capitalSocial = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly totalFuncionarios = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly totalFuncionarios_0_a_18 = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly totalFuncionarios_19_a_33 = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly totalFuncionarios_34_a_48 = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly totalFuncionarios_49_a_58 = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly totalFuncionarios_59_ou_mais = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly totalFuncionariosFemininos = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly totalFuncionariosMasculinos = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly totalFuncionariosDoGrupo = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly percentualFuncionariasMulheres = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly faturamentoEstimado = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly faturamentoEstimadoGrupo = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly crescimento2021 = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly crescimento2022 = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)
    readonly mediaCrescimento2021_a_2022 = new ObjectProperty<NumberFilter>(this, false, NumberFilterToKey, true)

    enchenteRs2024 = false

    private __isAdditiveModeOn = () => false

    // prettier-ignore
    public build() {
        const tgt: EmpresaFilter = {}

        const localizacaoFilter: LocalizacaoFilter ={}
        {
            let hasFilter = false
            this.localizacaoCircles.size > 0 && (hasFilter = true, localizacaoFilter.circles = [...this.localizacaoCircles.values()])
            this.localizacaoRoutes.size > 0 && (hasFilter = true, localizacaoFilter.routes = [...this.localizacaoRoutes.values()])
            this.localizacaoPolygons.size > 0 && (hasFilter = true, localizacaoFilter.polygons = [...this.localizacaoPolygons.values()])
            if (hasFilter) {
                localizacaoFilter.size = 3_000
                tgt.localizacao = localizacaoFilter
            }
        }

        this.situacaoCadastral.size > 0 && (tgt.situacaoCadastral = [...this.situacaoCadastral.values()])
        this.serMatrizOrFilial.size > 0 && (tgt.matrizOrFilial = [...this.serMatrizOrFilial.values()])
        this.empresaRazaoSocial.size > 0 && (tgt.empresaRazaoSocial = [...this.empresaRazaoSocial.values()])
        this.empresaNomeFantasia.size > 0 && (tgt.empresaNomeFantasia = [...this.empresaNomeFantasia.values()])
        this.empresaCodigo.size > 0 && (tgt.empresaCodigo = [...this.empresaCodigo.values()])
        this.atividadeEconomicaCodes.size > 0 && (tgt.atividadeEconomicaCodes = [...this.atividadeEconomicaCodes.values()])
        this.atividadeEconomicaTexts.size > 0 && (tgt.atividadeEconomicaTexts = [...this.atividadeEconomicaTexts.values()])
        this.tipoAtividadeEconomicaTexts.size > 0 && (tgt.tipoAtividadeEconomicaTexts = [...this.tipoAtividadeEconomicaTexts.values()])
        this.scoreRiscos.size > 0 && (tgt.scoreRiscos = [...this.scoreRiscos.values()])

        this.empresaGrupoCodes.size > 0 && (tgt.empresaGrupoCodes = [...this.empresaGrupoCodes.values()])
        this.empresaCnes.size > 0 && (tgt.empresaCnes = [...this.empresaCnes.values()])
        this.enderecoTexts.size > 0 && (tgt.enderecoTexts = [...this.enderecoTexts.values()])
        this.enderecoCodes.size > 0 && (tgt.enderecoCodes = [...this.enderecoCodes.values()])
        this.enderecoRuaTexts.size > 0 && (tgt.enderecoRuaTexts = [...this.enderecoRuaTexts.values()])
        this.enderecoRuaCodes.size > 0 && (tgt.enderecoRuaCodes = [...this.enderecoRuaCodes.values()])
        this.enderecoBairroTexts.size > 0 && (tgt.enderecoBairroTexts = [...this.enderecoBairroTexts.values()])
        this.enderecoBairroCodes.size > 0 && (tgt.enderecoBairroCodes = [...this.enderecoBairroCodes.values()])
        this.enderecoCidadeTexts.size > 0 && (tgt.enderecoCidadeTexts = [...this.enderecoCidadeTexts.values()])
        this.enderecoCidadeCodes.size > 0 && (tgt.enderecoCidadeCodes = [...this.enderecoCidadeCodes.values()])

        this.enderecoUfTexts.size > 0 && (tgt.enderecoUfTexts = [...this.enderecoUfTexts.values()])
        this.enderecoUfs.size > 0 && (tgt.enderecoUfs = [...this.enderecoUfs.values()])

        this.enderecoCepTexts.size > 0 && (tgt.enderecoCepTexts = [...this.enderecoCepTexts.values()])
        this.enderecoCeps.size > 0 && (tgt.enderecoCeps = [...this.enderecoCeps.values()])
        this.enderecoRegiaoMetropolitanaTexts.size > 0 && (tgt.enderecoRegiaoMetropolitanaTexts = [...this.enderecoRegiaoMetropolitanaTexts.values()])
        this.enderecoRegiaoMetropolitanaCodes.size > 0 && (tgt.enderecoRegiaoMetropolitanaCodes = [...this.enderecoRegiaoMetropolitanaCodes.values()])
        this.naturezaJuridicaTexts.size > 0 && (tgt.naturezaJuridicaTexts = [...this.naturezaJuridicaTexts.values()])
        this.naturezaJuridicaCodes.size > 0 && (tgt.naturezaJuridicaCodes = [...this.naturezaJuridicaCodes.values()])
        this.inicioAtividade.size > 0 && (tgt.inicioAtividade = [...this.inicioAtividade.values()])
        this.idadeEmpresaEmAnos.size > 0 && (tgt.idadeEmpresaEmAnos = [...this.idadeEmpresaEmAnos.values()])
        this.totalEmpresasNoGrupo.size > 0 && (tgt.totalEmpresasNoGrupo = [...this.totalEmpresasNoGrupo.values()])
        this.capitalSocial.size > 0 && (tgt.capitalSocial = [...this.capitalSocial.values()])
        this.totalFuncionarios.size > 0 && (tgt.totalFuncionarios = [...this.totalFuncionarios.values()])
        this.totalFuncionarios_0_a_18.size > 0 && (tgt.totalFuncionarios_0_a_18 = [...this.totalFuncionarios_0_a_18.values()])
        this.totalFuncionarios_19_a_33.size > 0 && (tgt.totalFuncionarios_19_a_33 = [...this.totalFuncionarios_19_a_33.values()])
        this.totalFuncionarios_34_a_48.size > 0 && (tgt.totalFuncionarios_34_a_48 = [...this.totalFuncionarios_34_a_48.values()])
        this.totalFuncionarios_49_a_58.size > 0 && (tgt.totalFuncionarios_49_a_58 = [...this.totalFuncionarios_49_a_58.values()])
        this.totalFuncionarios_59_ou_mais.size > 0 && (tgt.totalFuncionarios_59_ou_mais = [...this.totalFuncionarios_59_ou_mais.values()])
        this.totalFuncionariosFemininos.size > 0 && (tgt.totalFuncionariosFemininos = [...this.totalFuncionariosFemininos.values()])
        this.totalFuncionariosMasculinos.size > 0 && (tgt.totalFuncionariosMasculinos = [...this.totalFuncionariosMasculinos.values()])
        this.totalFuncionariosDoGrupo.size > 0 && (tgt.totalFuncionariosDoGrupo = [...this.totalFuncionariosDoGrupo.values()])
        this.percentualFuncionariasMulheres.size > 0 && (tgt.percentualFuncionariasMulheres = [...this.percentualFuncionariasMulheres.values()])
        this.faturamentoEstimado.size > 0 && (tgt.faturamentoEstimado = [...this.faturamentoEstimado.values()])
        this.faturamentoEstimadoGrupo.size > 0 && (tgt.faturamentoEstimadoGrupo = [...this.faturamentoEstimadoGrupo.values()])
        this.crescimento2021.size > 0 && (tgt.crescimento2021 = [...this.crescimento2021.values()])
        this.crescimento2022.size > 0 && (tgt.crescimento2022 = [...this.crescimento2022.values()])
        this.mediaCrescimento2021_a_2022.size > 0 && (tgt.mediaCrescimento2021_a_2022 = [...this.mediaCrescimento2021_a_2022.values()])

        this.socioCodigo.size > 0 && (tgt.socioCodigo = [...this.socioCodigo.values()])
        this.socioNome.size > 0 && (tgt.socioNome = [...this.socioNome.values()])

        this.prfCodigo.size > 0 && (tgt.prfCodigo = [...this.prfCodigo.values()])
        this.prfNome.size > 0 && (tgt.prfNome = [...this.prfNome.values()])

        this.pesCodigo.size > 0 && (tgt.pesCodigo = [...this.pesCodigo.values()])
        this.pesNome.size > 0 && (tgt.pesNome = [...this.pesNome.values()])

        this.enchenteRs2024 && (tgt.enchenteRs2024 = true)

        return tgt
    }

    bindIsAdditiveModeOn(listener: () => boolean) {
        this.__isAdditiveModeOn = listener
    }

    isInAdditiveMode() {
        return this.__isAdditiveModeOn()
    }

    setDataDeCorte(date: Date) {
        this.__dataDeCorte = date
    }

    clear() {
        this.serMatrizOrFilial.clear()
        this.situacaoCadastral.clear()
        this.novasUltimoMes.clear()
        this.localizacaoCircles.clear()
        this.localizacaoRoutes.clear()
        this.localizacaoPolygons.clear()
        this.empresaRazaoSocial.clear()
        this.empresaNomeFantasia.clear()
        this.empresaCodigo.clear()
        this.empresaGrupoCodes.clear()
        this.empresaCnes.clear()
        this.atividadeEconomicaCodes.clear()
        this.atividadeEconomicaTexts.clear()
        this.scoreRiscos.clear()
        this.enderecoTexts.clear()
        this.enderecoCodes.clear()
        this.enderecoRuaTexts.clear()
        this.enderecoRuaCodes.clear()
        this.enderecoBairroTexts.clear()
        this.enderecoBairroCodes.clear()
        this.enderecoCidadeTexts.clear()
        this.enderecoCidadeCodes.clear()
        this.enderecoUfTexts.clear()
        this.enderecoUfs.clear()
        this.enderecoCepTexts.clear()
        this.enderecoCeps.clear()
        this.enderecoRegiaoMetropolitanaTexts.clear()
        this.enderecoRegiaoMetropolitanaCodes.clear()
        this.naturezaJuridicaTexts.clear()
        this.naturezaJuridicaCodes.clear()
        this.inicioAtividade.clear()
        this.idadeEmpresaEmAnos.clear()
        this.socioCodigo.clear()
        this.socioNome.clear()
        this.capitalSocial.clear()
        this.totalFuncionarios.clear()
        this.totalFuncionarios_0_a_18.clear()
        this.totalFuncionarios_19_a_33.clear()
        this.totalFuncionarios_34_a_48.clear()
        this.totalFuncionarios_49_a_58.clear()
        this.totalFuncionarios_59_ou_mais.clear()
        this.totalFuncionariosFemininos.clear()
        this.totalFuncionariosMasculinos.clear()
        this.totalFuncionariosDoGrupo.clear()
        this.percentualFuncionariasMulheres.clear()
        this.totalEmpresasNoGrupo.clear()
        this.faturamentoEstimado.clear()
        this.faturamentoEstimadoGrupo.clear()
        this.crescimento2021.clear()
        this.crescimento2022.clear()
        this.mediaCrescimento2021_a_2022.clear()

        this.socioCodigo.clear()
        this.socioNome.clear()
        this.prfCodigo.clear()
        this.prfNome.clear()
        this.pesCodigo.clear()
        this.pesNome.clear()
    }
}

export class NovasUltimoMesProperty {
    private readonly __mngr: FilterManager
    private readonly __fnGetDataCorete: () => Date

    constructor(mngr: FilterManager, fnGetDataCorete: () => Date) {
        this.__mngr = mngr
        this.__fnGetDataCorete = fnGetDataCorete
    }

    get selected() {
        const refDate = this.__computeRefDate()
        for (const dateRange of this.__mngr.inicioAtividade.values()) {
            if (dateRange.from === refDate && !dateRange.to) {
                return true
            }
        }
        return false
    }

    clear() {
        this.remove()
    }

    get size() {
        return this.__mngr.inicioAtividade.size
    }

    add() {
        this.__mngr.situacaoCadastral.add(SituacaoCadastral.ativa)
        return this.__mngr.inicioAtividade.add({ from: this.__computeRefDate() })
    }

    remove() {
        this.__mngr.situacaoCadastral.remove(SituacaoCadastral.ativa)
        this.__mngr.inicioAtividade.remove({ from: this.__computeRefDate() })
    }

    private __computeRefDate() {
        const dataDeCorte = this.__fnGetDataCorete()
        return fns().formatISO(fns().subMonths(dataDeCorte, 1))
    }
}

export class ValueProperty<T> {
    private readonly __mngr: FilterManager
    private readonly __valueSet = new Set<T>()

    constructor(mngr: FilterManager) {
        this.__mngr = mngr
    }

    get size() {
        return this.__valueSet.size
    }

    values() {
        return this.__valueSet.values()
    }

    has(value: T | undefined) {
        if (lodash.isNil(value)) {
            return false
        }
        return this.__valueSet.has(value)
    }

    add(value: T | undefined) {
        const additive = this.__mngr.isInAdditiveMode()
        const valueSet = this.__valueSet

        if (additive) {
            if (!lodash.isNil(value)) {
                if (valueSet.has(value)) {
                    valueSet.delete(value)
                    return false
                }
                valueSet.add(value)
                return true
            }
            return false
        }

        if (lodash.isNil(value)) {
            valueSet.clear()
            return false
        }

        if (valueSet.size === 1) {
            if (valueSet.has(value)) {
                valueSet.clear()
                return false
            }
        }

        valueSet.clear()
        valueSet.add(value)
        return true
    }

    remove(value: T | undefined) {
        if (lodash.isNil(value)) {
            return
        }
        this.__valueSet.delete(value)
    }

    clear() {
        this.__valueSet.clear()
    }
}

export class ObjectProperty<T> {
    private readonly __isInAdditiveMode: () => boolean
    private readonly __valueMap = new Map<string, T>()
    private readonly __toKey: (v: T) => string
    private readonly __debug

    constructor(mngr: FilterManager, alwaysAdditive = false, toKey?: (v: T) => string, debug = false) {
        this.__debug = debug
        this.__isInAdditiveMode = alwaysAdditive ? () => true : mngr.isInAdditiveMode.bind(mngr)
        this.__toKey = toKey ? toKey : (v) => JSON.stringify(v)
    }

    get size() {
        return this.__valueMap.size
    }

    toKey(v: T) {
        return this.__toKey(v)
    }

    isEmpty() {
        return this.__valueMap.size === 0
    }

    keys() {
        return this.__valueMap.keys()
    }

    values() {
        return this.__valueMap.values()
    }

    has(value: T | undefined) {
        if (lodash.isNil(value)) {
            return false
        }
        const newKey = this.__toKey(value)
        return this.__valueMap.has(newKey)
    }

    hasKey(key: string) {
        return this.__valueMap.has(key)
    }

    add(value: T | undefined) {
        const additive = this.__isInAdditiveMode()
        const valueMap = this.__valueMap
        if (lodash.isNil(value)) {
            if (!additive) {
                valueMap.clear()
            }
            return false
        }

        const newKey = this.__toKey(value)

        if (additive) {
            if (valueMap.has(newKey)) {
                valueMap.delete(newKey)
                return false
            }

            valueMap.set(newKey, value)
            return true
        } else if (valueMap.has(newKey)) {
            valueMap.delete(newKey)
            return false
        } else {
            valueMap.clear()
            valueMap.set(newKey, value)
            return true
        }
    }

    remove(value: T | undefined) {
        if (this.__debug) {
            console.debug('remove: ' + value)
        }
        if (lodash.isNil(value)) {
            return
        }
        const newKey = this.__toKey(value)
        this.__valueMap.delete(newKey)
    }

    clear() {
        if (this.__debug) {
            console.debug('clear')
        }
        this.__valueMap.clear()
    }
}

export function NumberFilterToKey(filter: NumberFilter) {
    const key = `${filter.mode}-${filter.operator}-${filter.value}`
    if (filter.operator == NumberOperator.in_range || filter.operator == NumberOperator.out_of_range) {
        return `${key}:${filter.to}`
    }
    return key
}
