import React from 'react'
import { styled } from '@mui/material/styles'
import { Logger } from 'wdc-cube'
import { lodash } from 'src/utils'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { ClickAwayListener } from '@mui/base'
import Popper from '@mui/material/Popper'
import Paper from '@mui/material/Paper'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import { ViewSlot } from 'wdc-cube-react'

import {
    StringKeywordFilterScope,
    StringKeywordParcelFilterScope,
    StringKeywordOperator,
    JunctionMode,
    type KeywordOption
} from '../../tcm_scopes'

const LOG = Logger.get('StringFilterView')

const FilterView = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 5
})

const FormBox = styled(Box)({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f4f4f4',
    padding: '3px'
})

const FloatingIconButton = styled(IconButton)({
    marginBottom: 3
})

type StringKeywordFilterViewProps = {
    className?: string
    scope: StringKeywordFilterScope
}

class StringKeywordFilterViewClass implements FCClassContext<StringKeywordFilterViewProps> {
    // :: Fields
    scope!: StringKeywordFilterScope

    onSyncState({ scope }: StringKeywordFilterViewProps) {
        this.scope = scope
    }

    render({ className }: StringKeywordFilterViewProps) {
        const { scope } = this

        return (
            <FilterView className={className}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography>{scope.caption}</Typography>
                    <IconButton color="primary" size="small" onClick={this.handlePlusClick}>
                        <AddIcon />
                    </IconButton>
                </Box>

                {!!scope.conjunction.length &&
                    scope.conjunction.map((parcelScope) => (
                        <StringKeywordParcelView
                            key={parcelScope.uid}
                            acceptBlankValues={scope.acceptBlankValues}
                            scope={parcelScope}
                        />
                    ))}

                {!!scope.disjunction.length &&
                    scope.disjunction.map((parcelScope) => (
                        <StringKeywordParcelView
                            key={parcelScope.uid}
                            acceptBlankValues={scope.acceptBlankValues}
                            scope={parcelScope}
                        />
                    ))}
            </FilterView>
        )
    } //

    readonly handlePlusClick = () => {
        this.scope.onAddFilter(JunctionMode.OR).catch(LOG.caught)
    }
}

export const StringKeywordFilterView = classToFComponent(StringKeywordFilterViewClass, React)
export default StringKeywordFilterView

// :: StringParcelViewClass

type StringKeywordParcelViewProps = {
    className?: string
    acceptBlankValues: boolean
    scope: StringKeywordParcelFilterScope
}

class StringKeywordParcelViewClass implements FCClassContext<StringKeywordParcelViewProps> {
    scope!: StringKeywordParcelFilterScope
    multiline = false

    valueField = new (class {
        scope!: StringKeywordParcelFilterScope
        elm?: HTMLDivElement | null
        focused = false

        readonly setRef = (el: HTMLDivElement) => {
            this.elm = el
        }

        readonly handleFocus = () => {
            this.focused = true
            this.scope.update()
        }

        readonly handleBlur = () => {
            this.focused = false
            this.scope.update()
        }
    })()

    popper = new (class {
        scope!: StringKeywordParcelFilterScope
        open = false
    })()

    render({ className, acceptBlankValues, scope }: StringKeywordParcelViewProps) {
        this.scope = scope
        this.valueField.scope = scope
        this.popper.scope = scope

        this.multiline =
            scope.operator === StringKeywordOperator.is_in || scope.operator === StringKeywordOperator.is_not_in

        if (scope.valueVisible && this.valueField.focused) {
            const searchText = scope.value.trim()
            this.popper.open = scope.options.length > 0 && searchText.length > 0
        } else if (scope.options.length === 0) {
            this.popper.open = false
        }

        return (
            <FormBox className={className}>
                {lodash.isNotNil(scope.extra) && <ViewSlot scope={scope.extra} />}
                <FormControl variant="standard" size="small">
                    <InputLabel id={scope.uid}>
                        Critério ({scope.junctionMode === JunctionMode.AND ? 'AND' : 'OR'})
                    </InputLabel>
                    <Select
                        labelId={scope.uid}
                        defaultValue={`${StringKeywordOperator.contains}`}
                        value={`${scope.operator}`}
                        onChange={this.handleSelectChange}
                    >
                        <MenuItem value={StringKeywordOperator.contains}>Contém</MenuItem>
                        <MenuItem value={StringKeywordOperator.does_not_contain}>Não contém</MenuItem>
                        <MenuItem value={StringKeywordOperator.starts_with}>Começa com</MenuItem>
                        <MenuItem value={StringKeywordOperator.does_not_start_with}>Não começa com</MenuItem>
                        <MenuItem value={StringKeywordOperator.is}>É igual</MenuItem>
                        <MenuItem value={StringKeywordOperator.is_not}>Não é igual</MenuItem>
                        {acceptBlankValues && (
                            <MenuItem value={StringKeywordOperator.is_blank}>Está em branco</MenuItem>
                        )}
                        {acceptBlankValues && (
                            <MenuItem value={StringKeywordOperator.is_not_blank}>Não está em branco</MenuItem>
                        )}
                        <MenuItem value={StringKeywordOperator.is_in}>Está na lista</MenuItem>
                        <MenuItem value={StringKeywordOperator.is_not_in}>Não está na lista</MenuItem>
                    </Select>
                </FormControl>
                {scope.valueVisible && (
                    <ClickAwayListener onClickAway={this.handleClickAway}>
                        <div>
                            <TextField
                                ref={this.valueField.setRef}
                                label="Valor"
                                variant="standard"
                                value={scope.value}
                                multiline={this.multiline}
                                InputProps={{
                                    endAdornment: (
                                        <FloatingIconButton
                                            color="primary"
                                            size="small"
                                            onClick={this.handleClearClick}
                                        >
                                            <DeleteIcon />
                                        </FloatingIconButton>
                                    )
                                }}
                                onChange={this.handleValueChange}
                                onKeyDown={this.handleKeyDown}
                                onFocus={this.valueField.handleFocus}
                                onBlur={this.valueField.handleBlur}
                            />
                            <Popper open={this.popper.open} anchorEl={this.valueField.elm} placement="bottom-start">
                                <Paper sx={{ width: this.valueField.elm ? this.valueField.elm.offsetWidth : 184 }}>
                                    <List dense={true}>
                                        {scope.options.map((option) => {
                                            return (
                                                <ListItem key={option.key} disablePadding>
                                                    <ListItemButton onClick={() => this.handleListItemClick(option)}>
                                                        <ListItemText primary={option.label} />
                                                    </ListItemButton>
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                </Paper>
                            </Popper>
                        </div>
                    </ClickAwayListener>
                )}
            </FormBox>
        )
    }

    readonly handleSelectChange = (event: SelectChangeEvent) => {
        this.scope.onOperatorChange(+event.target.value)
    }

    readonly handleValueChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.scope.onValueChange(event.target.value).catch(LOG.caught)
    }

    readonly handleClearClick = () => {
        this.scope.onClear().catch(LOG.caught)
    }

    readonly handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            if (this.multiline && !e.ctrlKey) {
                return
            }
            this.scope.onEnterKeyPressed().catch(LOG.caught)
        }
    }

    readonly handleClickAway = () => {
        this.popper.open = false
        this.scope.update()
    }

    readonly handleListItemClick = (option: KeywordOption): void => {
        this.scope.onOptionSelected(option).catch(LOG.caught)
    }
}

export const StringKeywordParcelView = classToFComponent(StringKeywordParcelViewClass, React)
