import lodash from 'lodash'
import * as DateFnsNs from 'date-fns'
import { Logger, Presenter, action } from 'wdc-cube'
import * as lang from 'src/utils/lang'
import { type TCMTexts } from './tcm_texts'
import FilterManager from './tcm_filter_manager'
import { TheConsumerMarketPresenter } from './tcm_presenter'
import { TablePESScope } from './tcm_scopes'
import {
    TheConsumerMarketService,
    StringKeywordOperator,
    JunctionMode,
    type PESRow,
    type PESBean,
    type EmpresaFilter,
    type ColumnSort,
    type ColumnFilter
} from './tcm_service'

type PaginationState = {
    pageIndex: number
    pageSize: number
}

const LOG = Logger.get('TcmPresenterForPESListagem')

const service = TheConsumerMarketService.singleton()

export default class TcmPresenterForPESListagem extends Presenter<TablePESScope, TheConsumerMarketPresenter> {
    // :: Class methods

    constructor(owner: TheConsumerMarketPresenter) {
        super(owner, owner.scope.listagemTabs.pesListagem)
        this.__parent = owner
        this.__texts = owner.texts
        this.__filterManager = owner.filterManager
    }

    // :: Instance

    private readonly __parent: TheConsumerMarketPresenter
    private readonly __texts: TCMTexts
    private readonly __filterManager: FilterManager

    // :: Public API

    async initialize() {
        this.scope.caption = this.__texts.pesListagem
        this.scope.sorting = [{id: 'nome', desc: false}]
        this.scope.onSelectionChange = this.__onSelectionChange.bind(this)
        this.scope.onFiltrationChange = this.__onFiltrationChange.bind(this)
        this.scope.onPaginationChange = this.__onPaginationChange.bind(this)
        this.scope.onSortingChange = this.__onSortingChange.bind(this)
        this.scope.update = this.update
        LOG.debug('ready')
    }

    override release(): void {
        super.release()
    }

    clear() {
        this.scope.pageIndex = 0
        this.scope.pageSize = 100
        this.owner.filterPane.socioNome.clear()
        this.__filterManager.socioCodigo.clear()
        this.__filterManager.socioNome.clear()
    }

    async fetch(
        filter: EmpresaFilter,
        cfg?: {
            paginationState?: PaginationState
            sorting?: ColumnSort[]
            columnFilters?: ColumnFilter[]
        }
    ) {
        let { paginationState, sorting, columnFilters } = cfg ?? {}
        this.scope.loaded = false
        try {
            if (!columnFilters) {
                columnFilters = this.scope.filters
            }

            if (!paginationState) {
                paginationState = {
                    pageIndex: 0,
                    pageSize: this.scope.pageSize
                }
            }

            if (!sorting) {
                sorting = this.scope.sorting
            }

            let search_after: unknown
            if (paginationState.pageIndex > 0) {
                const lastRow = this.scope.dataset[this.scope.dataset.length - 1]
                if (lastRow) {
                    const sortValues: unknown[] = []
                    for(const sortItem of sorting) {
                        sortValues.push((lastRow as Record<string, unknown>)[sortItem.id])
                    }
                    sortValues.push(lastRow.ipf)
                    search_after = sortValues
                }
            }

            const resp = await service.fetch({
                listagem_pes: {
                    sorting,
                    columnFilters,
                    search_after,
                    pageSize: paginationState.pageSize
                },
                filter: filter
            })

            this.scope.pageIndex = paginationState.pageIndex
            this.scope.pageSize = paginationState.pageSize
            this.scope.sorting = sorting

            const rowDataset: PESRow[] = []
            const beanDataset = resp.listagem_pes ?? []
            for (let i = 0; i < beanDataset.length; i++) {
                const bean = beanDataset[i]
                const row: PESRow = {
                    key: bean.key,
                    ipf: bean.ipf,
                    cpf: bean.cpf,
                    nome: bean.nome ?? '',
                    cnpj: bean.cnpj ?? '',
                    pj_nome: bean.pj_nome ?? '',
                    sexo: bean.sexo ?? '',
                    dt_nascimento: isoToDate(bean.dt_nascimento),
                    celular: bean.celular ?? '',
                    dt_admissao: isoToDate(bean.dt_admissao),
                    endereco: descrivePessoaEndereco(bean),
                    cbos: codDscArrayToString(bean.cbo)
                }
                montaTextoLinkedin(row, bean)
                montaTextoInstagram(row, bean)

                rowDataset.push(row)
            }
            LOG.debug(`pes_dataset.length=${rowDataset.length}`)

            this.scope.rowCount = resp.listagem_pes_agg ? resp.listagem_pes_agg['pes-count'].value | 0 : 0
            this.scope.dataset = rowDataset
            this.scope.datasetVersion++

            this.__markSelected(undefined)
        } finally {
            this.scope.loaded = true
        }
    }

    private async __onSelectionChange(selectionMap: Map<string, boolean>) {
        const pesIdMap = new Map<string, boolean>()

        this.scope.dataset.forEach((row) => {
            if (selectionMap.get(row.key) === true) {
                pesIdMap.set(row.ipf, true)
            }
        })

        const prop = this.__filterManager.pesCodigo
        prop.clear()
        if (pesIdMap.size > 0) {
            prop.add({
                mode: JunctionMode.AND,
                operator: StringKeywordOperator.is_in,
                value: [...pesIdMap.keys()].join(', ')
            })
        }

        this.__markSelected(pesIdMap)
        this.__parent.fetchData(pesIdMap.size > 0 ? 'listagemDePES' : '')
    }

    private __markSelected(map: Map<string, boolean> | undefined) {
        if (!map) {
            map = this.collectExplicitValues()
        }

        for (const row of this.scope.dataset) {
            row.selected = map.has(row.ipf)
        }

        this.scope.update()
    }

    @action()
    async __onFiltrationChange(columnFilters: ColumnFilter[]) {
        this.scope.filters = columnFilters
        await this.fetch(this.__filterManager.build(), {
            columnFilters
        })
    }

    @action()
    async __onPaginationChange(pageIndex: number, pageSize: number) {
        if (pageSize !== this.scope.pageSize) {
            pageIndex = 0
        }
        await this.fetch(this.__filterManager.build(), {
            paginationState: { pageIndex, pageSize }
        })
    }

    @action()
    async __onSortingChange(sorting: ColumnSort[]) {
        await this.fetch(this.__filterManager.build(), { sorting })
    }

    private collectExplicitValues() {
        const codigoValMap = new Map<string, boolean>()

        const prop = this.__filterManager.pesCodigo

        for (const filterVal of prop.values()) {
            if (filterVal.value) {
                if (filterVal.operator == StringKeywordOperator.is_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigo && codigoValMap.set(codigo, true))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not_in) {
                    lang.splitValues(filterVal.value).forEach((codigo) => codigoValMap.delete(codigo))
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is) {
                    const codigo = lodash.trim(filterVal.value.trim())
                    codigo && codigoValMap.set(codigo, true)
                    continue
                }

                if (filterVal.operator == StringKeywordOperator.is_not) {
                    codigoValMap.delete(filterVal.value)
                    continue
                }
            }
        }
        return codigoValMap
    }
}

function codDscArrayToString(entries: { cod: string; dsc: string }[] | undefined | null) {
    if (!entries || entries.length === 0) {
        return ''
    }
    return entries.map((e) => e.dsc).join('; ')
}

function isoToDate(v: string | undefined | null) {
    if (!v) {
        return undefined
    }
    return DateFnsNs.parseISO(v)
}

function descrivePessoaEndereco(pessoaBean: PESBean) {
    const addrBean = pessoaBean.endereco
    if (!addrBean) {
        return ''
    }

    let fComma = ''
    const comma = () => {
        return fComma ? fComma : ((fComma = ', '), '')
    }

    const endereco: string[] = []
    addrBean.rua && endereco.push(`${comma()}${addrBean.rua.ds}`)
    addrBean.numero && endereco.push(`${comma()}${addrBean.numero}`)
    addrBean.complemento && endereco.push(`${comma()}${addrBean.complemento}`)
    addrBean.bairro && endereco.push(`${comma()}${addrBean.bairro.ds}`)

    if (addrBean.cidade && addrBean.uf) {
        endereco.push(`${comma()}${addrBean.cidade.ds}/${addrBean.uf.cd}`)
    } else {
        addrBean.cidade && endereco.push(`${comma()}${addrBean.cidade.ds}`)
        addrBean.uf && endereco.push(`${comma()}${addrBean.uf.cd}`)
    }

    const cep = lang.onlyNumbers(addrBean.cep)
    if (cep) {
        const cep0 = cep.substring(0, 5)
        const cep1 = cep.substring(5)
        endereco.push(` - CEP ${cep0}-${cep1}`)
    }

    return endereco.length > 0 ? endereco.join('') : ''
}

function montaTextoLinkedin(row: PESRow, pessoaBean: PESBean) {
    const lnkin = pessoaBean.linkedin
    if (!lnkin) {
        return undefined
    }

    row.lnkin_url = lnkin.url
    row.lnkin_titulo = lnkin.titulo

    const perfil: string[] = []
    lnkin.eh_ceo && perfil.push('CEO')
    lnkin.eh_comercial && perfil.push('Comercial')
    lnkin.eh_compras && perfil.push('Compras')
    lnkin.eh_coordenador && perfil.push('Coordenador')
    lnkin.eh_diretor && perfil.push('Diretor')
    lnkin.eh_gerente && perfil.push('Gerente')
    lnkin.eh_representante && perfil.push('Representante')

    if (perfil.length > 0) {
        row.lnkin_perfil = perfil.join('; ')
    }

    if (lnkin.duracao_em_anos > 0) {
        const lblAnos = lnkin.duracao_em_meses > 1 ? 'anos' : 'ano'
        const lblMeses = lnkin.duracao_em_meses > 1 ? 'meses' : 'mês'

        row.lnkin_duracao =
            lnkin.duracao_em_meses > 0
                ? `${lnkin.duracao_em_anos} ${lblAnos} e ${lnkin.duracao_em_meses} ${lblMeses}`
                : `${lnkin.duracao_em_anos} ${lblAnos}`
    } //
    else if (lnkin.duracao_em_meses > 0) {
        row.lnkin_duracao = lnkin.duracao_em_meses > 1 ? `${lnkin.duracao_em_meses} meses` : '1 mês'
    }
}

function montaTextoInstagram(row: PESRow, pessoaBean: PESBean) {
    const instgr = pessoaBean.instagram
    if (!instgr) {
        return undefined
    }

    row.instgr_url = instgr.url
    row.instgr_user = instgr.user
    row.instgr_qtd_followers = instgr.qtd_followers
    row.instgr_qtd_following = instgr.qtd_following
}
