import { enUS as coreUS, esES as coreES, ptBR as corePtBR } from '@mui/material/locale'
import { enUS as pickersEnUS, esES as pickersEsES, ptBR as pickersPtBR } from '@mui/x-date-pickers/locales'
import { enUS as dataGridEnUS, esES as dataGridEsES, ptBR as dataGridptBR } from '@mui/x-data-grid-pro'
import { type MRT_Localization } from 'material-react-table'
import { MRT_Localization_EN } from 'material-react-table/locales/en'
import { MRT_Localization_ES } from 'material-react-table/locales/es'
import { MRT_Localization_PT_BR } from 'material-react-table/locales/pt-BR'
import { type Locale as FnsLocale, format as fnsFormat } from 'date-fns'
import { enUS as fnsEnUS } from 'date-fns/locale/en-US'
import { es as fnsEsES } from 'date-fns/locale/es'
import { ptBR as fnsPtBR } from 'date-fns/locale/pt-BR'
import { type DatePickerProps } from '@mui/x-date-pickers'

require('dayjs/locale/es-us')
require('dayjs/locale/es')
require('dayjs/locale/pt-br')

type CoreLocation = typeof corePtBR
type PickerLocation = typeof pickersPtBR
type DataGridLocation = typeof dataGridptBR
type FnsLocation = {
    locale: FnsLocale
    formatDate: (date: Date | string | number | undefined | null) => string
}
//fnsFormat(new Date(), 'dd/MM/yyyy', {locale: fns})

function buildLocation(
    name: string,
    core: CoreLocation,
    picker: PickerLocation,
    dataGrid: DataGridLocation,
    datePicker: DatePickerProps<unknown>,
    mrt: MRT_Localization,
    fns: FnsLocation
) {
    return {
        name,
        mui: {
            components: {
                MuiAlert: core.components?.MuiAlert ?? {},
                MuiBreadcrumbs: core.components?.MuiBreadcrumbs ?? {},
                MuiTablePagination: core.components?.MuiTablePagination ?? {},
                MuiRating: core.components?.MuiRating ?? {},
                MuiAutocomplete: core.components?.MuiAutocomplete ?? {},
                MuiPagination: core.components?.MuiPagination ?? {},
                MuiLocalizationProvider: picker.components?.MuiLocalizationProvider ?? {},
                MuiDataGrid: dataGrid.components?.MuiDataGrid ?? {},
                DatePicker: datePicker ?? {}
            }
        },
        mrt,
        fns
    }
}

export type LocaleType = ReturnType<typeof buildLocation>

const enUSLocalization = (() => {
    const pickerLocation = pickersEnUS.components!.MuiLocalizationProvider.defaultProps
    const datePicker: DatePickerProps<unknown> = {
        format: 'MM/DD/YYYY',
        localeText: pickerLocation.localeText
    }
    const fnsLocation: FnsLocation = {
        locale: fnsEnUS,
        formatDate: (date) => {
            if (date) {
                return fnsFormat(date, 'MM/dd/yyyy', {locale: fnsEnUS})
            }
            return ''
        }
    }
    return buildLocation('en-US', coreUS, pickersEnUS, dataGridEnUS, datePicker, MRT_Localization_EN, fnsLocation)
})()

const ptBRLocalization = (() => {
    const pickerLocation = pickersPtBR.components!.MuiLocalizationProvider.defaultProps
    const datePicker: DatePickerProps<unknown> = {
        format: 'DD/MM/YYYY',
        localeText: pickerLocation.localeText
    }
    const fnsLocation: FnsLocation = {
        locale: fnsPtBR,
        formatDate: (date) => {
            if (date) {
                return fnsFormat(date, 'dd/MM/yyyy', {locale: fnsPtBR})
            }
            return ''
        }
    }
    return buildLocation('pt-BR', corePtBR, pickersPtBR, dataGridptBR, datePicker, MRT_Localization_PT_BR, fnsLocation)
})()

const esESLocalization = (() => {
    const pickerLocation = pickersEsES.components!.MuiLocalizationProvider.defaultProps
    const datePicker: DatePickerProps<unknown> = {
        format: 'DD/MM/YYYY',
        localeText: pickerLocation.localeText
    }
    const fnsLocation: FnsLocation = {
        locale: fnsEsES,
        formatDate: (date) => {
            if (date) {
                return fnsFormat(date, 'dd/MM/yyyy', {locale: fnsEsES})
            }
            return ''
        }
    }
    return buildLocation('es-ES', coreES, pickersEsES, dataGridEsES, datePicker, MRT_Localization_ES, fnsLocation)
})()

const localeMap = new Map<string, LocaleType>()
{
    localeMap.set('en', enUSLocalization)
    localeMap.set('en-US', enUSLocalization)
}

{
    localeMap.set('pt', ptBRLocalization)
    localeMap.set('pt-BR', ptBRLocalization)
}

{
    localeMap.set('es', esESLocalization)
    localeMap.set('es-ES', esESLocalization)
}

export const LocaleProvider = {
    get(lang = navigator.language) {
        return localeMap.get(lang) ?? localeMap.get(lang.substring(0, 2)) ?? ptBRLocalization
    }
}
