import * as React from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'

import lodash from 'src/utils/lodash'
import * as DateFnsNs from 'date-fns'
import { Logger, NOOP_VOID } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'
import { LocaleProvider } from 'src/utils/views'
import { formatCPFOrCNPJ } from 'src/utils/formatter-utils'

import Box from '@mui/material/Box'
import {
    MaterialReactTable,
    useMaterialReactTable,
    getIsRowSelected,
    MRT_ShowHideColumnsButton,
    type MRT_TableInstance,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_RowSelectionState
} from 'material-react-table'
import { type PaginationState, type SortingState, type OnChangeFn } from '@tanstack/react-table'

import { Caption } from './helpers/tcm-card'
import { colors } from './helpers/tcm_view_utils'

import { TableQSAScope, type QSARow } from '../tcm_scopes'

const LOG = Logger.get('TCM-VIEW-LIST-QSA')

const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 3,
        paddingRight: 3,
        width: 0,
        alignSelf: 'stretch'
    },
    rowSelected: {
        backgroundColor: colors.selected
    },
    tableHead: {
        '& .Mui-TableHeadCell-Content-Wrapper': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    }
})

const SpanNumber = styled('span')({
    alignSelf: 'stretch',
    flexGrow: 1,
    textAlign: 'right'
})

let GRID_COLUMN_DEFS: MRT_ColumnDef<QSARow>[] = []

export type QsaListagemViewProps = {
    className?: string
    style?: React.CSSProperties
    scope: TableQSAScope
}

export class QsaListagemViewClass implements FCClassContext<QsaListagemViewProps> {
    scope!: TableQSAScope
    classes!: ReturnType<typeof useStyles>['classes']
    loaded = false
    loading = true
    mrtLocation = LocaleProvider.get().mrt
    table!: MRT_TableInstance<QSARow>
    data?: QSARow[]
    dataVer = 0
    rowSelection: MRT_RowSelectionState = {}

    onSyncState(props: QsaListagemViewProps) {
        initializeStatics()
        this.scope = props.scope
        this.loaded = this.scope.loaded
        this.loading = !this.loaded
        this.classes = useStyles().classes
    }

    render(props: QsaListagemViewProps): JSX.Element {
        const { classes, scope, loading: isLoading, rowSelection } = this

        if (!this.data || this.dataVer !== scope.datasetVersion) {
            this.data = [...scope.dataset]
            this.dataVer = scope.datasetVersion
            for (const selKey of Object.keys(rowSelection)) {
                delete rowSelection[selKey]
            }

            for (const row of this.data) {
                if (row.selected) {
                    rowSelection[this.getRowId(row)] = true
                }
            }
        }

        const table = (this.table = useMaterialReactTable({
            localization: this.mrtLocation,
            columns: GRID_COLUMN_DEFS,
            data: this.data,
            defaultDisplayColumn: { enableResizing: true },
            enableColumnResizing: true,
            enableFullScreenToggle: false,
            enableRowSelection: true,
            enableSelectAll: false,
            enableColumnOrdering: true,
            enableStickyHeader: true,
            enableGrouping: false,
            enableRowVirtualization: true,
            rowVirtualizerOptions: { overscan: 5 },
            enableRowNumbers: false,
            layoutMode: 'grid-no-grow',
            getRowId: this.getRowId,

            // Overall appearance
            muiTablePaperProps: {
                className: clsx(classes.view, props.className)
            },
            renderTopToolbarCustomActions: () => {
                return <Caption>{scope.caption}</Caption>
            },
            renderToolbarInternalActions: ({ table }) => {
                return (
                    <Box display="flex" columnGap={1}>
                        <MRT_ShowHideColumnsButton table={table} />
                    </Box>
                )
            },
            // Header
            muiTableHeadCellProps: {
                className: classes.tableHead
            },
            // Filtering
            manualFiltering: true,
            enableGlobalFilter: false,
            onColumnFiltersChange: this.emitColumnFilters,
            onGlobalFilterChange: this.emitGlobalFilter,
            onRowSelectionChange: this.emitRowSelectionChange,
            // pagging
            manualPagination: true,
            rowCount: scope.rowCount,
            onPaginationChange: this.emitPaginationChange,
            onSortingChange: this.emitSortingChange,
            muiPaginationProps: {
                rowsPerPageOptions: [100, 1000, 10_000],
                showRowsPerPage: true,
                showLastButton: false
            },
            // Content
            muiTableContainerProps: { sx: { maxHeight: '600px' } },
            muiTableBodyRowProps: (props) => ({
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onClick: (_event) => {
                    const { row } = props
                    const checked = !getIsRowSelected(props)
                    row.toggleSelected(checked)
                    //this.emitOnRowClick(row, checked)
                },
                style: {
                    cursor: 'pointer',
                    userSelect: 'none'
                }
            }),
            muiSelectCheckboxProps: (props) => ({
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onChange: (_event, checked) => {
                    const { row } = props
                    row.toggleSelected(checked)
                    //this.emitOnRowClick(row, checked)
                }
            }),
            initialState: {
                density: 'compact',
                columnVisibility: {
                    cnpj: true,
                    socio_nome: true,
                    socio_cpf_or_cnpj: true,
                    entrada_sociedade_dta: true,
                    qualificacao: true,
                    endereco: true,
                    lnkin_url: false,
                    lnkin_titulo: false,
                    lnkin_perfil: false,
                    lnkin_duracao: false,
                    instgr_url: false,
                    instgr_user: false,
                    instgr_qtd_followers: false,
                    instgr_qtd_following: false
                },
                rowSelection
            },
            state: {
                isLoading,
                pagination: {
                    pageIndex: scope.pageIndex,
                    pageSize: scope.pageSize
                },
                sorting: this.scope.sorting,
                columnFilters: this.scope.filters,
                rowSelection
            }
        }))

        const tableRec = table as unknown as Record<string, unknown>
        tableRec.$scope = scope

        return <MaterialReactTable table={table} />
    }

    getRowId = (originalRow: QSARow) => {
        return originalRow.key
    }

    emitRowSelectionChange: OnChangeFn<MRT_RowSelectionState> = (stateUpdater) => {
        if (lodash.isFunction(stateUpdater)) {
            this.rowSelection = stateUpdater(this.rowSelection)
            //this.scope.update()
            this.scope.onSelectionChange(new Map(Object.entries(this.rowSelection))).catch(LOG.caught)
        }
    }

    emitColumnFilters: OnChangeFn<MRT_ColumnFiltersState> = (filterStateUpdater) => {
        if (lodash.isFunction(filterStateUpdater)) {
            const newFiler = filterStateUpdater(this.scope.filters)
            this.scope.onFiltrationChange(newFiler).catch(LOG.caught)
        }
    }

    emitGlobalFilter = () => {
        LOG.debug('emitGlobalFilter: Nao deveria estar ativo')
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    emitPaginationChange: OnChangeFn<PaginationState> = (pageStateUpdater) => {
        if (lodash.isFunction(pageStateUpdater)) {
            const newState = pageStateUpdater({
                pageIndex: this.scope.pageIndex,
                pageSize: this.scope.pageSize
            })

            this.scope.onPaginationChange(newState.pageIndex, newState.pageSize).catch(LOG.caught)
        }
    }

    emitSortingChange: OnChangeFn<SortingState> = (pageStateUpdater) => {
        if (lodash.isFunction(pageStateUpdater)) {
            const newSort = pageStateUpdater(this.scope.sorting)
            this.scope.onSortingChange(newSort)
        }
    }
}

export default classToFComponent(QsaListagemViewClass, React)

// :: Internal

let initializeStatics = () => {
    GRID_COLUMN_DEFS = static_newGridColumnDefs()
    initializeStatics = NOOP_VOID
}

function static_newGridColumnDefs(): MRT_ColumnDef<QSARow>[] {
    return [
        {
            enableColumnFilter: false,
            accessorKey: 'cnpj',
            header: 'CNPJ',
            size: 140,
            enableHiding: false
        },
        {
            enableColumnFilter: false,
            accessorKey: 'socio_nome',
            header: 'Nome',
            size: 250
        },
        {
            enableColumnFilter: false,
            accessorKey: 'socio_cpf_or_cnpj',
            header: 'CPF/CNPJ',
            size: 140,
            Cell: ({ cell }) => {
                const val = cell.getValue<string | null | undefined>()
                return <span>{formatCPFOrCNPJ(val)}</span>
            }
        },
        {
            enableColumnFilter: false,
            accessorKey: 'entrada_sociedade_dta',
            header: 'Entrada Sociedade',
            size: 140,
            filterVariant: 'date-range',
            Cell: ({ cell }) => {
                const val = cell.getValue<Date | null | undefined>()
                if (!val) {
                    return <span></span>
                }

                return <SpanNumber>{DateFnsNs.formatDate(val, 'dd/MM/yyyy')}</SpanNumber>
            }
        },
        {
            enableColumnFilter: false,
            accessorKey: 'qualificacao',
            header: 'Qualificação',
            size: 100
        },
        {
            enableColumnFilter: false,
            accessorKey: 'endereco',
            header: 'Endereço',
            size: 450
        },
        {
            enableColumnFilter: false,
            accessorKey: 'lnkin_url',
            header: 'LinkedIn URL',
            size: 130
        },
        {
            accessorKey: 'lnkin_titulo',
            header: 'LinkedIn Título',
            size: 130
        },
        {
            accessorKey: 'lnkin_perfil',
            header: 'LinkedIn Perfil',
            size: 130
        },
        {
            accessorKey: 'lnkin_duracao',
            header: 'LinkedIn Duração',
            size: 130
        },
        {
            enableColumnFilter: false,
            accessorKey: 'instgr_url',
            header: 'Instagram URL',
            size: 130
        },
        {
            accessorKey: 'instgr_user',
            header: 'Instagram Usuário',
            size: 130
        },
        {
            accessorKey: 'instgr_qtd_followers',
            header: 'Instagram Qtd. Seguidores',
            size: 120
        },
        {
            accessorKey: 'instgr_qtd_following',
            header: 'Instagram Qtd. Seguindo',
            size: 120
        }
    ]
}
