import { TcmPresenterForStringFilter } from './tcm_filter_presenter_for_string'

import { Logger } from 'wdc-cube'
import { StringFilterScope, StringParcelFilterScope, JunctionMode, FilterExtraAeScope } from '../tcm_scopes'
import { TcmPresenterForFilterPane } from './tcm_presenter_filter_pane'
import { ValueProperty, StringFilter, TipoAtividadeEconomicaTextFilter } from '../tcm_filter_manager'

const LOG = Logger.get('TCM-AEFilter')

export class TcmPresenterForAtivEconomica extends TcmPresenterForStringFilter {
    defaultDomain = 1

    constructor(
        owner: TcmPresenterForFilterPane,
        scope: StringFilterScope,
        valueProperty: ValueProperty<StringFilter>,
        onSearch?: (text: string) => Promise<string[]>
    ) {
        super(owner, scope, valueProperty, onSearch)
    }

    populateUsingValueProporty(domain: number) {
        const oldDefaultDomain = this.defaultDomain
        try {
            this.scope.conjunction.length = 0
            this.scope.disjunction.length = 0

            for (const entry of this.valueProperty.values() as IterableIterator<TipoAtividadeEconomicaTextFilter>) {
                this.defaultDomain = entry.domain ?? domain
                const entryScope = entry.mode === JunctionMode.AND ? this.addConjuntion() : this.addDisjuntion()
                entryScope.operator = entry.operator
                entryScope.value = entry.value ?? ''
            }
        } finally {
            this.defaultDomain = oldDefaultDomain
        }
    }

    addConjuntion(): StringParcelFilterScope {
        const andScope = super.addConjuntion()
        andScope.hasInOption = true
        const extraScope = new FilterExtraAeScope()
        extraScope.domain = this.defaultDomain
        extraScope.onChange = this.onDomainChange.bind(this, extraScope)
        extraScope.update = this.update
        andScope.extra = extraScope
        return andScope
    }

    addDisjuntion(): StringParcelFilterScope {
        const orScope = super.addDisjuntion()
        orScope.hasInOption = true
        const extraScope = new FilterExtraAeScope()
        extraScope.domain = this.defaultDomain
        extraScope.onChange = this.onDomainChange.bind(this, extraScope)
        extraScope.update = this.update
        orScope.extra = extraScope
        return orScope
    }

    protected async onDomainChange(domainScope: FilterExtraAeScope, value: number) {
        domainScope.domain = value
        LOG.debug(`onDomainChange(value=${value})`)
    }

    protected override populateWithExtraValues(filter: TipoAtividadeEconomicaTextFilter, parcelScope: StringParcelFilterScope) {
        if (parcelScope.extra) {
            const extraScope = parcelScope.extra as FilterExtraAeScope
            filter.domain = extraScope.domain
        }
        return filter
    }
}
