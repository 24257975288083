import React from 'react'
import clsx from 'clsx'
import lodash from 'src/utils/lodash'
import * as DateFnsNs from 'date-fns'
import { makeStyles } from 'tss-react/mui'
import { styled } from '@mui/material/styles'
import { Logger, NOOP_VOID } from 'wdc-cube'
import { FCClassContext, classToFComponent } from 'src/utils/views'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import {
    MaterialReactTable,
    useMaterialReactTable,
    getIsRowSelected,
    MRT_ShowHideColumnsButton,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_RowSelectionState
} from 'material-react-table'
import { type PaginationState, type SortingState, type OnChangeFn } from '@tanstack/react-table'

import { LocaleProvider } from 'src/utils/views'

import DownloadIcon from '@mui/icons-material/Download'

import { Caption } from './helpers/tcm-card'
import { formatNumberToCompactLongForm, colors } from './helpers/tcm_view_utils'
import { TableEmpresaScope, type EmpresaRow } from '../tcm_scopes'

const LOG = Logger.get('TCM-VIEW-LIST-PJ')
const useStyles = makeStyles()({
    view: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 3,
        paddingRight: 3,
        width: 0,
        alignSelf: 'stretch'
    },
    rowSelected: {
        backgroundColor: colors.selected
    },
    tableHead: {
        '& .Mui-TableHeadCell-Content-Wrapper': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    }
})

const SpanNumber = styled('span')({
    alignSelf: 'stretch',
    flexGrow: 1,
    textAlign: 'right'
})

let GRID_COLUMN_DEFS: MRT_ColumnDef<EmpresaRow>[] = []

export type EmpresaTableViewProps = {
    className?: string
    style?: React.CSSProperties
    scope: TableEmpresaScope
}

export class EmpresaTableViewClass implements FCClassContext<EmpresaTableViewProps> {
    // :: Fields
    scope!: TableEmpresaScope
    loaded = false
    loading = true
    mrtLocation = LocaleProvider.get().mrt
    classes!: ReturnType<typeof useStyles>['classes']
    data?: EmpresaRow[]
    dataVer = 0
    rowSelection: MRT_RowSelectionState = {}

    onSyncState(props: EmpresaTableViewProps) {
        initializeStatics()
        this.scope = props.scope
        this.loaded = this.scope.loaded
        this.loading = !this.loaded
        this.classes = useStyles().classes
    }

    render(props: EmpresaTableViewProps): JSX.Element {
        const { classes, scope, loading: isLoading, rowSelection } = this

        if (!this.data || this.dataVer !== scope.datasetVersion) {
            this.data = [...scope.dataset]
            this.dataVer = scope.datasetVersion

            for (const selKey of Object.keys(rowSelection)) {
                delete rowSelection[selKey]
            }

            for (const row of this.data) {
                if (row.selected) {
                    rowSelection[this.getRowId(row)] = true
                }
            }
        }

        const table = useMaterialReactTable({
            localization: this.mrtLocation,
            columns: GRID_COLUMN_DEFS,
            data: this.data,
            defaultDisplayColumn: { enableResizing: true },
            enableColumnResizing: true,
            enableFullScreenToggle: false,
            enableRowSelection: true,
            enableSelectAll: false,
            enableColumnOrdering: true,
            enableStickyHeader: true,
            enableGrouping: false,
            enableRowVirtualization: true,
            rowVirtualizerOptions: { overscan: 5 },
            enableRowNumbers: false,
            layoutMode: 'grid-no-grow',
            getRowId: this.getRowId,

            // Overall appearance
            muiTablePaperProps: {
                className: clsx(classes.view, props.className)
            },
            renderTopToolbarCustomActions: () => {
                return <Caption>{scope.caption}</Caption>
            },
            renderToolbarInternalActions: ({ table }) => {
                return (
                    <Box display="flex" columnGap={1}>
                        <MRT_ShowHideColumnsButton table={table} />
                        {scope.downloadEnabled && (
                            <IconButton edge="start" color="inherit" onClick={this.emitDownloadClick}>
                                <DownloadIcon />
                            </IconButton>
                        )}
                    </Box>
                )
            },
            // Header
            muiTableHeadCellProps: {
                className: classes.tableHead
            },
            // Filtering
            manualFiltering: true,
            enableGlobalFilter: false,
            onColumnFiltersChange: this.emitColumnFilters,
            onGlobalFilterChange: this.emitGlobalFilter,
            onRowSelectionChange: this.emitRowSelectionChange,
            // pagging
            manualPagination: true,
            rowCount: scope.rowCount,
            onPaginationChange: this.emitPaginationChange,
            onSortingChange: this.emitSortingChange,
            muiPaginationProps: {
                rowsPerPageOptions: [100, 1000, 10_000],
                showRowsPerPage: true,
                showLastButton: false
            },
            // Content
            muiTableContainerProps: { sx: { maxHeight: '600px' } },
            muiTableBodyRowProps: (props) => ({
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onClick: (_event) => {
                    const { row } = props
                    const checked = !getIsRowSelected(props)
                    row.toggleSelected(checked)
                },
                style: {
                    cursor: 'pointer',
                    userSelect: 'none'
                }
            }),
            muiSelectCheckboxProps: (props) => ({
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                onChange: (_event, checked) => {
                    const { row } = props
                    row.toggleSelected(checked)
                }
            }),
            initialState: {
                density: 'compact',
                columnVisibility: {
                    cnpj: true,
                    cnes: false,
                    nome: true,
                    natureza_juridica: false,
                    natureza_juridica_grp: false,
                    situacao_cadastral_nme: false,
                    dta_inicio_atividade: false,
                    cnae: true,
                    cnae_descricao: true,
                    endereco_cep: false,
                    endereco_rua_nome: false,
                    endereco_numero: false,
                    endereco_complemento: false,
                    endereco_bairro_nome: false,
                    endereco_cidade_nome: true,
                    endereco_uf: true,
                    endereco_lat: false,
                    endereco_lon: false,
                    socios_nomes: false,
                    emp_captial_social: false,
                    emp_idade_anos: false,
                    emp_qtd_grupo: false,
                    func_qtd_2020: false,
                    func_qtd_2021: false,
                    func_qtd_2022: false,
                    func_qtd: true,
                    func_qtd_masc: false,
                    func_qtd_fem: false,
                    func_qtd_0_a_18: false,
                    func_qtd_19_a_33: false,
                    func_qtd_34_a_48: false,
                    func_qtd_49_a_58: false,
                    func_qtd_59_ou_mais: false,
                    faturamento_estimado: true,
                    faturamento_estimado_grupo: true,
                    matriz_cnpj: false,
                    matriz_nome: false,
                    matriz_uf: false,
                    grupo_cnpj: false,
                    telefone1: false,
                    telefone2: false,
                    email: false,
                    salario_total: false,
                    salario_total_grupo: false,
                    func_qtd_matriz_e_filiais: false,
                    qtd_filiais: false,
                    web_nome: false,
                    web_categoria: false,
                    web_nota: false,
                    web_qtd_avaliacoes: false,
                    web_site: false,
                    web_tel: false,
                    web_tempo_medio: false,
                    optante_simples: false,
                    porte_codigo: false
                },
                rowSelection
            },
            state: {
                isLoading,
                pagination: {
                    pageIndex: scope.pageIndex,
                    pageSize: scope.pageSize
                },
                sorting: this.scope.sorting,
                columnFilters: this.scope.filters,
                rowSelection
            }
        })

        const tableRec = table as unknown as Record<string, unknown>
        tableRec.$scope = scope

        return <MaterialReactTable table={table} />
    }

    getRowId = (originalRow: EmpresaRow) => {
        return originalRow.cnpj
    }

    emitRowSelectionChange: OnChangeFn<MRT_RowSelectionState> = (stateUpdater) => {
        if (lodash.isFunction(stateUpdater)) {
            this.rowSelection = stateUpdater(this.rowSelection)
            this.scope.onSelectionChange(new Map(Object.entries(this.rowSelection))).catch(LOG.caught)
        }
    }

    emitColumnFilters: OnChangeFn<MRT_ColumnFiltersState> = (filterStateUpdater) => {
        if (lodash.isFunction(filterStateUpdater)) {
            const newFiler = filterStateUpdater(this.scope.filters)
            this.scope.onFiltrationChange(newFiler).catch(LOG.caught)
        }
    }

    emitGlobalFilter = () => {
        LOG.debug('emitGlobalFilter: Nao deveria estar ativo')
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    emitPaginationChange: OnChangeFn<PaginationState> = (pageStateUpdater) => {
        if (lodash.isFunction(pageStateUpdater)) {
            const newState = pageStateUpdater({
                pageIndex: this.scope.pageIndex,
                pageSize: this.scope.pageSize
            })

            this.scope.onPaginationChange(newState.pageIndex, newState.pageSize).catch(LOG.caught)
        }
    }

    emitSortingChange: OnChangeFn<SortingState> = (pageStateUpdater) => {
        if (lodash.isFunction(pageStateUpdater)) {
            const newSort = pageStateUpdater(this.scope.sorting)
            this.scope.onSortingChange(newSort)
        }
    }

    readonly emitDownloadClick = () => this.scope.onDownloadClick().catch(LOG.caught)
}

export default classToFComponent(EmpresaTableViewClass, React)

let initializeStatics = () => {
    GRID_COLUMN_DEFS = static_newGridColumnDefs()
    initializeStatics = NOOP_VOID
}

function static_newGridColumnDefs(): MRT_ColumnDef<EmpresaRow>[] {
    return [
        {
            enableColumnFilter: false,
            accessorKey: 'cnpj',
            header: 'CNPJ',
            size: 120,
            enableHiding: false,
            Footer: () => <span>TOTAL:</span>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'cnes',
            header: 'CNES',
            size: 100
        },
        {
            enableColumnFilter: false,
            accessorKey: 'nome',
            header: 'Nome',
            size: 240
        },
        {
            enableColumnFilter: false,
            accessorKey: 'natureza_juridica',
            header: 'Natureza Jurídica',
            size: 140
        },
        {
            enableColumnFilter: false,
            accessorKey: 'natureza_juridica_grp',
            header: 'Natureza Jurídica Agrupamento',
            size: 140,
            filterVariant: 'checkbox',
            Cell: ({ cell }) => {
                const val = cell.getValue<number | null | undefined>()
                return <span>{val === 1 ? 'SIM' : 'NÃO'}</span>
            }
        },
        {
            enableColumnFilter: false,
            accessorKey: 'situacao_cadastral_nme',
            header: 'Situação Cadastral',
            size: 140
        },
        {
            enableColumnFilter: false,
            accessorKey: 'dta_inicio_atividade',
            header: 'Início Atividade',
            size: 140,
            filterVariant: 'date-range',
            Cell: ({ cell }) => {
                const val = cell.getValue<Date | null | undefined>()
                if (!val) {
                    return <span></span>
                }

                return <SpanNumber>{DateFnsNs.formatDate(val, 'dd/MM/yyyy')}</SpanNumber>
            }
        },
        {
            enableColumnFilter: false,
            accessorKey: 'cnae',
            header: 'CNAE',
            size: 100
        },
        {
            enableColumnFilter: false,
            accessorKey: 'cnae_descricao',
            header: 'Descrição CNAE',
            size: 240
        },
        {
            enableColumnFilter: false,
            accessorKey: 'endereco_cep',
            header: 'CEP',
            size: 130
        },
        {
            enableColumnFilter: false,
            accessorKey: 'endereco_rua_nome',
            header: 'Logradouro',
            size: 130
        },
        {
            enableColumnFilter: false,
            accessorKey: 'endereco_numero',
            header: 'Número',
            size: 100
        },
        {
            enableColumnFilter: false,
            accessorKey: 'endereco_complemento',
            header: 'Complemento',
            size: 100
        },
        {
            enableColumnFilter: false,
            accessorKey: 'endereco_bairro_nome',
            header: 'Bairro',
            size: 130
        },
        {
            enableColumnFilter: false,
            accessorKey: 'endereco_cidade_nome',
            header: 'Município',
            size: 120
        },
        {
            enableColumnFilter: false,
            accessorKey: 'endereco_uf',
            header: 'UF',
            size: 50
        },
        {
            enableColumnFilter: false,
            accessorKey: 'socios_nomes',
            header: 'Sócios',
            size: 200,
            Cell: ({ cell }) => {
                const nomes = cell.getValue<string>()
                return (
                    <Tooltip title={nomes}>
                        <span>{nomes}</span>
                    </Tooltip>
                )
            }
        },
        {
            enableColumnFilter: false,
            accessorKey: 'emp_captial_social',
            header: 'Capital Social',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'emp_idade_anos',
            header: 'Idade Emp. Anos',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{cell.getValue<number>()}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'emp_qtd_grupo',
            header: 'Qtde Emp. Grupo',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_2020',
            header: 'Funcionários 2020',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_2021',
            header: 'Funcionários 2021',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_2022',
            header: 'Funcionários 2022',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd',
            header: 'Funcionários',
            size: 120,
            Cell: ({ cell }) => <span>{formatNumberToCompactLongForm(cell.getValue<number>())}</span>,
            Footer: ({ table }) => {
                const scope = (table as unknown as { $scope: TableEmpresaScope }).$scope
                const value = formatNumberToCompactLongForm(scope.total.func_qtd)
                return <SpanNumber>{value}</SpanNumber>
            }
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_masc',
            header: 'Func. Masculinos',
            size: 120
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_fem',
            header: 'Func. Femininos',
            size: 120
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_0_a_18',
            header: 'Func. de 0 a 18',
            size: 120
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_19_a_33',
            header: 'Func. de 19 a 33',
            size: 120
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_34_a_48',
            header: 'Func. de 34 a 48',
            size: 120
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_49_a_58',
            header: 'Func. de 49 a 58',
            size: 120
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_59_ou_mais',
            header: 'Func. de 59 ou mais',
            size: 120
        },
        {
            enableColumnFilter: false,
            accessorKey: 'faturamento_estimado',
            header: 'Faturamento',
            size: 120,
            Cell: ({ cell }) => <span>{formatNumberToCompactLongForm(cell.getValue<number>())}</span>,
            Footer: ({ table }) => {
                const scope = (table as unknown as { $scope: TableEmpresaScope }).$scope
                const value = formatNumberToCompactLongForm(scope.total.faturamento_estimado)
                return <SpanNumber>{value}</SpanNumber>
            }
        },
        {
            enableColumnFilter: false,
            accessorKey: 'faturamento_estimado_grupo',
            header: 'Fat. Grupo',
            size: 120,
            Cell: ({ cell }) => <span>{formatNumberToCompactLongForm(cell.getValue<number>())}</span>,
            Footer: ({ table }) => {
                const scope = (table as unknown as { $scope: TableEmpresaScope }).$scope
                const value = formatNumberToCompactLongForm(scope.total.faturamento_estimado_grupo)
                return <SpanNumber>{value}</SpanNumber>
            }
        },
        {
            enableColumnFilter: false,
            accessorKey: 'matriz_cnpj',
            header: 'CNPJ Matriz',
            size: 140
        },
        {
            enableColumnFilter: false,
            accessorKey: 'matriz_nome',
            header: 'Nome Matriz',
            size: 250
        },
        {
            enableColumnFilter: false,
            accessorKey: 'matriz_uf',
            header: 'UF Matriz',
            size: 50
        },
        {
            enableColumnFilter: false,
            accessorKey: 'grupo_cnpj',
            header: 'CNPJ Grupo Emp.',
            size: 140
        },
        {
            enableColumnFilter: false,
            accessorKey: 'telefone1',
            header: 'Telefone 1 - RF',
            size: 80
        },
        {
            enableColumnFilter: false,
            accessorKey: 'telefone2',
            header: 'Telefone 2 - RF',
            size: 80
        },
        {
            enableColumnFilter: false,
            accessorKey: 'email',
            header: 'E-mail',
            size: 150
        },
        {
            enableColumnFilter: false,
            accessorKey: 'salario_total',
            header: 'Salário Total',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'salario_total_grupo',
            header: 'Salário Total Grupo',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'func_qtd_matriz_e_filiais',
            header: 'Total de Funcionários - Matriz + Filiais',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'qtd_filiais',
            header: 'Total de Filiais',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'web_nome',
            header: 'Internet - Nome',
            size: 150
        },
        {
            enableColumnFilter: false,
            accessorKey: 'web_categoria',
            header: 'Internet - Categorias',
            size: 80
        },
        {
            enableColumnFilter: false,
            accessorKey: 'web_nota',
            header: 'Internet - Nota',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'web_qtd_avaliacoes',
            header: 'Internet - Total de avaliações',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'web_site',
            header: 'Internet - Website',
            size: 150
        },
        {
            enableColumnFilter: false,
            accessorKey: 'web_tel',
            header: 'Internet - Telefone',
            size: 80
        },
        {
            enableColumnFilter: false,
            accessorKey: 'web_tempo_medio',
            header: 'Internet - Tempo Médio',
            size: 120,
            Cell: ({ cell }) => <SpanNumber>{formatNumberToCompactLongForm(cell.getValue<number>())}</SpanNumber>
        },
        {
            enableColumnFilter: false,
            accessorKey: 'endereco_lat',
            header: 'Internet Latitude',
            size: 50
        },
        {
            enableColumnFilter: false,
            accessorKey: 'endereco_lon',
            header: 'Internet Longitude',
            size: 50
        },
        {
            enableColumnFilter: false,
            accessorKey: 'optante_simples',
            header: 'Optante Simples',
            size: 80,
            filterVariant: 'checkbox',
            Cell: ({ cell }) => {
                let val = ''
                switch (cell.getValue<string>() ?? '') {
                    case 'S':
                        val = 'SIM'
                        break
                    case 'N':
                        val = 'NÃO'
                        break
                    default:
                        val = ''
                }
                return <span>{val}</span>
            }
        },
        {
            enableColumnFilter: false,
            accessorKey: 'porte_codigo',
            header: 'Porte da Empresa - RF',
            size: 80
        }
    ]
}
